import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  editBranch,
  fetchChildBranches,
  fetchLocation,
  fetchLogs,
  fetchPartnerProfile,
  fetchPartnerUser,
  fetchRecharges,
  fetchSearchLogs,
  fetchStaff,
  getUserApi,
  singleUserDetails,
} from "../../services/api";

export const getAllUserFun = createAsyncThunk(
  "user/fetchUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getPartnerUser = createAsyncThunk(
  "user/partnerUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchPartnerUser();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBusinessUserProfile = createAsyncThunk(
  "user/business_profile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchPartnerProfile(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getUserLogs = createAsyncThunk(
  "user/logs",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchLogs(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getChildBranches = createAsyncThunk(
  "user/child_branches",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchChildBranches(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getLocation = createAsyncThunk(
  "user/location",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchLocation(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getRecharges = createAsyncThunk(
  "user/recharges",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchRecharges(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSearchLogs = createAsyncThunk(
  "user/search",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchSearchLogs(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getStaff = createAsyncThunk(
  "user/staff",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchStaff(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editPartnerUser = createAsyncThunk(
  "user/edit-partner",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editBranch(data.id, data.formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSingleCustomer = createAsyncThunk(
  "user/single-customer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await singleUserDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language: "",
    user: [],
    partner_users: [],
    partner_profile: {},
    child_branches: [],
    recharges: [],
    logs: [],
    search: [],
    staff: [],
    location: [],
    token: "",
    single_customer: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserFun.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllUserFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.user = action.payload;
      })
      .addCase(getAllUserFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
      .addCase(getPartnerUser.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getPartnerUser.fulfilled, (state, action) => {
        state.submitting = false;
        state.partner_users = action.payload.data;
        state.success = true;
        state.error = null;
      })
      .addCase(getPartnerUser.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getBusinessUserProfile.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getBusinessUserProfile.fulfilled, (state, action) => {
        state.submitting = false;
        state.partner_profile = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(getBusinessUserProfile.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getChildBranches.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getChildBranches.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.child_branches = action.payload;
      })
      .addCase(getChildBranches.rejected, (state, action) => {
        state.error = action.payload;
        state.submitting = false;
        state.success = false;
      })
      .addCase(getLocation.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.location = action.payload;
        state.success = true;
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getRecharges.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getRecharges.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.recharges = action.payload;
        state.success = true;
      })
      .addCase(getRecharges.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getUserLogs.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getUserLogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.logs = action.payload;
        state.success = true;
      })
      .addCase(getUserLogs.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getSearchLogs.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSearchLogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.search = action.payload;
        state.success = true;
      })
      .addCase(getSearchLogs.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getStaff.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.staff = action.payload;
        state.success = true;
      })
      .addCase(getStaff.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(editPartnerUser.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editPartnerUser.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(editPartnerUser.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getSingleCustomer.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSingleCustomer.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.single_customer = action.payload;
        state.success = true;
      })
      .addCase(getSingleCustomer.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});
export default userSlice.reducer;
