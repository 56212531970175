import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePendingRequest } from "../../../Reducer/PendingRequest/pendingRequestSlice";
import { BaseUrl, frontUrl } from "../../../data/data";
import { FcApproval, FcDisapprove } from "react-icons/fc";
import { editPrimaryDetails } from "../../../Reducer/branch/branchSlice";

const Block = ({
  label,
  value,
  image,
  statuskey,
  status,
  commentkey,
  handlePrimaryDetails,
  setSubmitTrigger,
  secondValue,
  secondLabel,
}) => {
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handlePrimaryDetails(name, value);
  };

  const handleClick = async (e) => {
    const { name, value } = e.target;
    try {
      handlePrimaryDetails(name, value);
    } catch (error) {
      console.log(error);
    }
  };

  const onHide = () => setShow(!show);

  console.log("image", "--->", image);
  return (
    <div className="block-box">
      {secondValue && secondLabel && (
        <h5>
          <span>
            {secondLabel}:{secondValue}
          </span>
        </h5>
      )}
      {!image ? (
        <h5>
          <span>
            {label}:{status !== "Pending" ? value : <p>Not Uploaded</p>}
          </span>
        </h5>
      ) : (
        <label>
          {label} :
          {status !== "" ? (
            <img src={`${frontUrl}/${value}`} alt={label} width="50%" />
          ) : (
            <p>Not Uploaded</p>
          )}
        </label>
      )}
      {status == "Hold" ? (
        <>
          <div className="form-group">
            <label>Comment</label>
            <input
              className="form-control"
              name={commentkey}
              onChange={handleChange}
            />
          </div>

          <Button
            type="submit"
            variant="success"
            name={statuskey}
            value={"Approved"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Approved
          </Button>
          <Button
            type="submit"
            variant="danger"
            name={statuskey}
            value={"Rejected"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Rejected
          </Button>
        </>
      ) : status == "Approved" ? (
        <>
          <FcApproval />
          <div className="form-group">
            <label>Comment</label>
            <input
              className="form-control"
              name={commentkey}
              onChange={handleChange}
            />
          </div>
          <Button
            type="submit"
            variant="danger"
            name={statuskey}
            value={"Rejected"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Rejected
          </Button>
        </>
      ) : status == "Rejected" ? (
        <FcDisapprove />
      ) : (
        <>
          <div className="form-group">
            <label>Comment</label>
            <input
              className="form-control"
              name={commentkey}
              onChange={handleChange}
            />
          </div>

          <Button
            type="submit"
            variant="success"
            name={statuskey}
            value={"Approved"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Approved
          </Button>
          <Button
            type="submit"
            variant="danger"
            name={statuskey}
            value={"Rejected"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Rejected
          </Button>
        </>
      )}

      <Modal show={show} onHide={onHide}>
        <Modal.Body>
          <p>Are you sure ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onHide();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setSubmitTrigger(true);
              onHide();
            }}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Block;
