import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import { metricsData } from "../../../data/data";
import TradeBox from "../../Tools/TradeBox";
import BoxItem from "../../Tools/BoxList";
import { metricsFun } from "../../../Reducer/dashboard/dashboardSlice";

const Metrics = () => {
  const dispatch = useDispatch();
  const metrics = useSelector(state => state.dashboard?.metrics);
  console.log("metrics", metrics);
  const error = useSelector(state => state.dashboard.error);
  React.useEffect(() => {
    dispatch(metricsFun({ time: "This week" }))
  }, [dispatch])
  const filterData = (data) => {
    console.log("data", data);
    let formData = new FormData();
    formData.append("time", data);
    dispatch(metricsFun(formData));
  }
  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }
  return (<TradeBox parentStyle={"set1"} childStyle={"trade"} heading={"Metrics"} onClick={filterData} component={<BoxItem parentStyle={"col-6 col-sm-6 col-lg-4 mb-3"} childStyle={"st"} data={metrics} />}  />
)
}
export default Metrics;