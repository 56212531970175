import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SendNotificationOption from "./sendNotificationOption";
import UsersOption from "./usersOption";
import { sendNotificationUser } from "../../../Reducer/sendNotification/sendNotificationSlice";
import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
import Button from "../../Tools/Button";
import SearchBar from "../../Tools/SearchBar";
const NotificationFilter = () => {
    const [active, setActive] = React.useState('trade');
    const tradeUsers = useSelector(state => state.sendNotificationusers?.tradeUsers);
    const businessUsers = useSelector(state => state.sendNotificationusers?.businessUsers);
    console.log("businnessUsers", businessUsers);
    console.log("tradeUsers", tradeUsers)
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(sendNotificationUser());
    }, [dispatch]);

    const activeUser = active == "trade" ? tradeUsers : businessUsers;
    return (<div class="sideb">
        <div class="set">
            <h6>Send to</h6>
            <SendNotificationOption />
            <UsersOption />
        </div>

        <div class="set">
            <h5>Specific users</h5>
            <div class="switch-button">
                <Button style={`switch-button-case ${active == "trade" && 'left'}`} title={"Trade users"} onClick={()=>setActive('trade')} />
                <Button style={`switch-button-case ${active == "business" && 'left'}`} title={"Business users"} onClick={()=>setActive('business')} />
            </div>
            <SearchBar placeholder={"Search Users"} />
            <ul>
                {activeUser && activeUser.map((item, index) => {
                    return <li><ChecboxWithSpan
                        parentStyle={"checkcontainer"}
                        childStyle={"radiobtn"}
                        inType={"checkbox"}
                        inputStyle={"quality"}
                        name={`user[${index}]`}
                        value={"T1"} />{item.full_name.English}</li>
                })}
            </ul>
        </div>
    </div>)
}

export default NotificationFilter