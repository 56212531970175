import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { pagesColumn } from "../../../data/data";
import { pagesFun } from "../../../Reducer/pages/pagesSlice";
import UpdateContent from "./updateContent";
import { editPageFun } from "../../../Reducer/pages/editpageSlice";
import {
  deletePendingAprrovalFun,
  deletePagesFun,
} from "../../../services/api";
import { fetchView, fetchDelete } from "../../Functions/DataTable";
const PagesPage = () => {
  const rows = useSelector((state) => state.pages?.pages);
  const error = useSelector((state) => state.pages?.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [action, setAction] = React.useState([]);
  React.useEffect(() => {
    dispatch(pagesFun());
    fetchActions();
  }, [dispatch]);

  const fetchActions = async () => {
    const edit = fetchView(
      "Edit",
      "",
      "edit_page",
      dispatch,
      navigate,
      "edt11.png",
      "view"
    );
    const hide = fetchDelete(
      "Hide",
      "",
      "edit_page",
      dispatch,
      navigate,
      "hide.png",
      "view"
    );
    const deleted = fetchDelete(
      "Remove",
      deletePagesFun,
      dispatch,
      "dlt112.png",
      pagesFun
    );
    setAction([edit, hide, deleted]);
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  const data =
    rows &&
    rows.map((item) => {
      return {
        ...item,
        last_updated: moment.unix(item.last_updated).format("DD/MMM/YYYY"),
      };
    });
  return (
    <div class="overview banner_images mb-0">
      <h3>{"Blogs"}</h3>
      <UpdateContent
        parentStyle={"set_list2 mb-0"}
        childStyle={"uph3"}
        pagesColumn={pagesColumn}
        rows={data ? data : []}
        action={action}
        heading={"Updated Content"}
      />
    </div>
  );
};

export default PagesPage;
