import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputs from "../../Tools/FormInput/Newforminput";
import swal from "sweetalert";

export default function EditProfile() {
  const location = useLocation();
  const meta = location.state.data;
  const [profiledata, setprofile] = useState(meta);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };
  const submitting = false;
  const schema = Yup.object().shape({
    phone_number: Yup.string().required("phone number is required"),
    first_name: Yup.string().required("first name  is required"),
    last_name: Yup.string().required("Last name  is required"),
    email: Yup.string().required("email is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmitform = async (data) => {
    console.log("data", data);
    if (submitting == false) {
      let formData = new FormData();
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("phone_number", data.phone_number);
      formData.append("email", data.email);
      formData.append("profile", selectedImage);
      // dispatch(saveprofileForm(formData));
      swal("success", "Form submit successfully", "success");
    }
  };
  document.title = "Edit Profile";

  return (
    <div className="overview">
      {profiledata ? (
        <>
          <h3 class="pt-0">
            {"Edit Basic Details"}{" "}
            <span class="saveCancel">
              <NavLink to="/profile">{"Cancel"}</NavLink>
              <button class="save" type="submit">
                {"Save"}
              </button>
            </span>
          </h3>

          <div class="my_profile">
            <form onSubmit={handleSubmit(onSubmitform, onError)}>
              <div class="row">
                <div class="col-sm-3 col-xl-2">
                  <div class="imgs">
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    <img
                      class="w-100"
                      src={
                        selectedImage == null
                          ? profiledata.image
                          : selectedImage
                      }
                      align="img"
                    />
                    <span>
                      <img
                        src="../assets/images/cmra.png"
                        alt="icon"
                        onClick={() => {
                          document.getElementById("fileInput").click();
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div class="col-sm-9 col-xl-10">
                  <div class="row">
                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"First Name"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        value={profiledata.first_name}
                        name={"first_name"}
                        placeholder={"Type first name"}
                      />
                      {errors.first_name && <p>{errors.first_name.message}</p>}
                    </div>

                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"Last Name"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        value={profiledata.last_name}
                        name={"last_name"}
                        placeholder={"Type last name"}
                      />
                      {errors.last_name && <p>{errors.last_name.message}</p>}
                    </div>

                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"Phone"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"number"}
                        event={register}
                        value={profiledata.phone_number}
                        name={"phone_number"}
                        placeholder={"Type phone number"}
                      />
                      {errors.phone_number && (
                        <p>{errors.phone_number.message}</p>
                      )}
                    </div>
                    <div class="col-sm-6 col-xl-4 ">
                      <h6>{"Email"}</h6>

                      <FormInputs
                        style={"form-control"}
                        intype={"email"}
                        event={register}
                        value={profiledata.email}
                        name={"email"}
                        placeholder={"Type email"}
                      />
                      {errors.email && <p>{errors.email.message}</p>}
                    </div>
                  </div>
                </div>
                <div class="col-12 d-block d-sm-none albtn"></div>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
