import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BoostrapModel from "../../Tools/BoostrapModel";
import AddCategory from "./AddCategory";
import { useDispatch, useSelector } from "react-redux";
import BasicTable from "../../Tools/Table";
import { categoryColumn } from "../../../data/data";
import { getCategories } from "../../../Reducer/category/categorySlice";
import AddSubCategory from "./AddSubCategory";
import EditCategory from "./EditCategory";

const Category = () => {
  const [show, setShow] = useState(false);
  const [subShow, setSubShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [parent_id, setParent_id] = useState("");
  const [editData, setEditData] = useState({});

  const dispatch = useDispatch();
  const { success, submitting, error, all_categories } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(getCategories(0));
  }, []);

  const actions = [
    {
      label: "Add SubCategory",
      style: "Reject",
      onclick: (row) => {
        setParent_id(row.id);
        setSubShow(!subShow);
      },
      type: "text",
    },
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        console.log(row);
        setEditShow(!editShow);
        setEditData(row);
      },
      type: "text",
    },
  ];

  const view = {
    label: "View Subcategory",
    image: "eye2.png",
    path: "/sub-categories",
  };

  const onHide = () => {
    setShow(false);
    setSubShow(false);
    setEditShow(false);
  };
  return (
    <div>
      <h3>
        Category <Button onClick={() => setShow(!show)}>Add Category</Button>
      </h3>
      <BasicTable
        rows={all_categories ? all_categories : []}
        columns={categoryColumn}
        actions={actions}
        view={view}
      />
      <BoostrapModel
        show={show}
        onHide={onHide}
        heading={"Add Category"}
        component={<AddCategory onHide={onHide} />}
      />
      <BoostrapModel
        show={subShow}
        onHide={onHide}
        heading={"Add SubCategory"}
        component={<AddSubCategory onHide={onHide} parent_id={parent_id} />}
      />
      <BoostrapModel
        show={editShow}
        onHide={onHide}
        heading={"Edit Category"}
        component={<EditCategory onHide={onHide} editdata={editData} />}
      />
    </div>
  );
};

export default Category;
