import SubscriptionBox from "../../Tools/SubscriptionBox";
const Subscription = ({ data, onClick }) => {
  const styles = {
    weekly: {},
    standard: {},
    monthly: { background: "#4C7EE8" },
    annually: { background: "#FCBC00" },
  };
  return (
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="Trade"
        role="tabpanel"
        aria-labelledby="Trade-tab"
      >
        <div class="row">
          {data.length > 0 &&
            data.map((item) => {
              const itemStyle = styles[item.duration.toLowerCase()];
              return (
                <SubscriptionBox
                  parentSTyle={"col-md-6 col-xxl-3 mb-3"}
                  childStyle={"set"}
                  style={itemStyle}
                  benefits={[]}
                  item={item}
                  onClick={() => onClick(item.id)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
