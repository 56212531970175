import React from "react";
import { Link } from "react-router-dom";
import FormInput from "../../Tools/FormInput";
import Button from '../../Tools/Button';
import FormTextArea from "../../Tools/Textarea";
import InputFormFile from "../../Tools/InputFormFile";
const EditPageForm = ({ onHide }) => {
    const [mediaList, setMediaList] = React.useState([]);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    if (selectedFile) {
        setMediaList([...mediaList, selectedFile]);
        setSelectedFile(null);
    }
    return (<>
    <form>
        <div className="form-group">
            <label>{"Section Title"}</label>
            <FormInput intype={"text"} style={"form-control"} placeholder={"About"} />
        </div>
        <div className="form-group">
            <h5>{"Section images & videos"}</h5>
            <div class="Information">
                <ul>
                    <li>
                        <div class="st" style={{ border: "1px dashed #4C7EE8" }}>
                            <i class="fa-solid fa-plus"></i>
                            <InputFormFile accept={"image/*,video/*"} onChange={handleFileChange} />
                        </div>
                    </li>
        
                        {mediaList.map((media, index) => (
                            <li key={index}>
                                {media.type.startsWith('image/') ? (
                                    <div class="st"><img src={URL.createObjectURL(media)} alt={`Media ${index}`} /></div>
                                ) : (
                                    <div class="st"><video controls>
                                        <source src={URL.createObjectURL(media)} type={media.type} />
                                    </video></div>
                                )}
                            </li>
                        ))}
            
                </ul>
                <h6 class="text-start">{"Allowed file formats:"} <b>{"JPG, PNG"}</b> <span>{"Optimal size:"} <b>{"900*600px"}</b></span></h6>
            </div>
        </div>

        <div class="form-group">
            <label>{"Section content"}</label>
            <FormTextArea placeholder={"content for the section"} />
        </div>

        <div class="modal-footer">
            <Button type={"button"} style={"btn btn-secondary"} title={"Close"} onClick={onHide} />
            <Button type={"button"} style={"btn btn-primary"} title={"Update content"} onClick={onHide} />
        </div></form></>)
}

export default EditPageForm