import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addNewBlog } from "../../../Reducer/blogs/blogSlice";
import {
  getCategories,
  getSubCategories,
} from "../../../Reducer/category/categorySlice";
import Tags from "../../Tools/Tags";
import { editorModules } from "../../../data/data";
import { editorFormats } from "../../../data/data";
import { useNavigate } from "react-router-dom";
import { findSlug } from "../../../services/api";

import { Base64 } from "js-base64";

const AddBlogs = () => {
  const { register, handleSubmit } = useForm();
  const [desc, setDesc] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [faq, setFaq] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [slugAvail, setSlugAvail] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { submitting, success, error, all_categories, sub_categories } =
    useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getCategories(0));
  }, []);

  const onSubmit = async (data) => {
    try {
      const newFaq = JSON.stringify(faq);

      const addHeadingIds = desc
        .match(/<h[1-3]>(.*?)<\/h[1-3]>/g)
        ?.map((x) => x.match(/<h[1-3]>(.*?)<\/h[1-3]>/)[1]);

      const result = desc.replace(/<h[1-3]>/g, function (match) {
        const headingId = addHeadingIds.shift();
        const headingLevel = match.charAt(2);
        return `<h${headingLevel} id="${headingId}">`;
      });

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", result);
      formData.append("image", data.image[0]);
      formData.append("time", moment(data.publishDate).unix());
      formData.append("cat_id", data.cat_id);
      formData.append("subcat_id", data.subcat_id);
      formData.append("short_description", data.short_description);
      formData.append("author_name", data.author_name);
      formData.append("author_bio", "");
      formData.append("author_image", data.author_image[0]);
      formData.append("slug", data.slug);
      for (let index = 0; index < tags.length; index++) {
        formData.append("tags[]", tags[index].text);
      }
      formData.append("meta_title", data.meta_title);
      formData.append("meta_description", data.meta_description);
      formData.append("faqs", newFaq);
      formData.append("meta_tags", JSON.stringify(metaTags));
      dispatch(addNewBlog(formData));
      if (success) {
        navigate("/blogs");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (content, delta, source, editor) => {
    setDesc(content);
  };

  const handleCategory = (e) => {
    const { value } = e.target;
    dispatch(getSubCategories(value));
    setCategory(value);
  };

  const handleAddFaq = () => {
    setFaq([...faq, { que: "", ans: "" }]);
  };

  const handleFaqQue = (e, i) => {
    let newFaq = [...faq];
    newFaq[i][e.target.name] = e.target.value;
    setFaq(newFaq);
  };

  const handleFaqAns = (content, i) => {
    let newFaq = [...faq];
    newFaq[i]["ans"] = Base64.encode(content);
    setFaq(newFaq);
  };

  let handleRemove = (i) => {
    let newFaq = [...faq];
    newFaq.splice(i, 1);
    setFaq(newFaq);
  };

  const handleSlug = async (e) => {
    try {
      const body = { id: 0, slug: e.target.value };
      const response = await findSlug(body);
      setSlugAvail(response);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(faq);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Blog Image</label>
          <input
            type="file"
            className="form-control"
            name="image"
            {...register("image", { required: "Image is required" })}
          />
        </div>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            {...register("title", { required: "Title is required" })}
          />
        </div>
        <div className="form-group">
          <label>Slug</label>
          <input
            type="text"
            className="form-control"
            name="slug"
            {...register("slug", { required: "slug is required" })}
            onChange={handleSlug}
          />
        </div>
        <p>{slugAvail.message}</p>

        <div className="form-group">
          <label>Description</label>
          <ReactQuill
            theme="snow"
            modules={editorModules}
            formats={editorFormats}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Short Description</label>
          <input
            type="text"
            className="form-control"
            name="slug"
            {...register("short_description", {
              required: "short description is required",
            })}
          />
        </div>
        <div className="form-group">
          <label>Meta Title</label>
          <input
            type="text"
            className="form-control"
            name="meta_title"
            {...register("meta_title", { required: "meta title is required" })}
          />
        </div>
        <div className="form-group">
          <label>Meta Description</label>
          <input
            type="text"
            className="form-control"
            name="meta_description"
            {...register("meta_description", {
              required: "meta description is required",
            })}
          />
        </div>
        <div className="form-group my-3">
          <label>Faq</label>
          <Button onClick={handleAddFaq}> Add Faq</Button>
        </div>
        {faq.map((el, i) => (
          <div className="form-group" key={i}>
            <label>Question</label>
            <input
              type="text"
              className="form-control"
              name="que"
              value={el.que}
              onChange={(e) => handleFaqQue(e, i)}
            />
            <label>Answer</label>
            <ReactQuill
              theme="snow"
              modules={editorModules}
              formats={editorFormats}
              name="ans"
              onChange={(content) => handleFaqAns(content, i)}
            />
            <Button onClick={() => handleRemove(i)}>Remove</Button>
          </div>
        ))}

        <div className="form-group my-3">
          <label>Meta Tags</label>
          <Tags tags={metaTags} setTags={setMetaTags} />
        </div>

        <div className="col-sm-12 mb-3">
          <div className="form-group">
            <label>Category</label>
            <select
              name="cat_id"
              className="form-control"
              {...register("cat_id", { required: "Category is required" })}
              onChange={handleCategory}
            >
              {all_categories &&
                all_categories.length > 0 &&
                all_categories.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-sm-12 mb-3">
          <div className="form-group">
            <label>Sub Category</label>
            <select
              name="subcat_id"
              className="form-control"
              {...register("subcat_id")}
            >
              {sub_categories &&
                sub_categories.length > 0 &&
                sub_categories.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>Author Image</label>
          <input
            type="file"
            className="form-control"
            name="author_image"
            {...register("author_image", {
              required: "Author Images is required",
            })}
          />
        </div>
        <div className="form-group">
          <label>Author Name</label>
          <input
            type="text"
            className="form-control"
            name="author_name"
            {...register("author_name", { required: "AuthorName is required" })}
          />
        </div>

        <div className="form-group">
          <label>Publish Date</label>
          <input
            type="date"
            className="form-control"
            name="publishDate"
            {...register("publishDate", {
              required: "PublishDate is required",
            })}
          />
        </div>
        <div className="form-group my-3">
          <label>Tags</label>
          <Tags tags={tags} setTags={setTags} />
        </div>
        <Button type="submit" disabled={!slugAvail.status || desc.length == 0}>
          Add Blog
        </Button>
      </form>
    </div>
  );
};

export default AddBlogs;
