import React from "react";
import { useSelector, useDispatch } from "react-redux";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import { getBannerImage } from "../../../Reducer/bannerImages/bannerImagesSlice";
import BasicTable from "../../Tools/Table";
import { bannerImagesColumn } from "../../../data/data";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditForm from "./EditForm";
const BannerImages = () => {
  const data = useSelector((state) => state.bannerImages?.bannerImages);
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getBannerImage({ time: "This Weeks" }));
  });

  const actions = [
    {
      label: "change",
      onclick: (id) => {
        console.log("id", id);
        setShow(!show);
      },
      type: "text",
    },
    {
      label: "Remove",
      style: "Remove",
      onclick: (id) => {
        console.log("id", id);
      },
      type: "text",
    },
  ];
  return (
    <div class="overview banner_images">
      <h3>{"Banner images"}</h3>
      <div class="set_list2 mb-0">
        <h3 class="uph3">
          {"Update banners"}
          <HeadingWithDatePicker />
        </h3>
        <BasicTable
          columns={bannerImagesColumn}
          rows={data && data}
          actions={actions}
        />
        <BoostrapModel
          show={show}
          heading={"Change image"}
          component={<EditForm onHide={() => setShow(!show)} />}
          onHide={() => setShow(!show)}
        />
      </div>
    </div>
  );
};
export default BannerImages;
