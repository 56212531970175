import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import DynamicChart from "../../Tools/DynamicGraph";
import TradeBox from "../../Tools/TradeBox";
import { getGraphData } from "../../../Reducer/dashboard/dashboardSlice";
const LiveTrafffic = () => {
    const dispatch = useDispatch();
    const graphData = useSelector(state => state.dashboard?.graphData);
    console.log("graphData", graphData);
    const error = useSelector(state => state.dashboard.error);
    React.useEffect(() => {
        dispatch(getGraphData({ time: "This week" }))
    }, [dispatch]);
    if (error && error.status) {
        switch (error.status) {
            case 403:
                toast.error(error.data.message);
                break;
            default:
                toast.error("Something went wrong");
                break;
        }
    }
    return (
        <TradeBox
            parentStyle={"set1 graf"}
            childStyle={"trade"}
            heading={"Live traffic"}
            inlineStyle={{ border: "none" }}
            onClick={(data) => console.log("data", data)}
            component={
                Object.keys(graphData).length > 0 ? (
                    <DynamicChart categories={graphData.categories} data={graphData.data} />
                ) : (
                    <div>Loading...</div>
                )
            }
        />
    );

}
export default LiveTrafffic;