import React from "react";
const FormInputs = ({style, placeholder,intype,event,name,value,min,max,checked}) => {

    const getInput=()=>{
        let val;
        switch (intype) {
            case 'checkbox':
                val=<input class={style} name={name} type={intype} {...event(name)} defaultChecked={checked ? 'checked' :''} placeholder={placeholder}/>;
                break;
        
                default:
                val=<input class={style} name={name}  minlength={min} maxlength={max} type={intype} defaultValue={value} {...event(name)} checked={checked ? 'checked' :''} placeholder={placeholder}/>;
                break;
        }
        return val;
    }


    return (
        <>{getInput()}</>
    )
}
export default FormInputs;

