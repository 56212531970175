import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabNavItem from "../../Tools/TabNavItem";
import BasicDetails from "../../Tools/BasicTable";
import { getAccountDetails } from "../../../Reducer/userAccount/accountDetailsSlice";
import {
  dealerAccountBasicDetailsColumn,
  privateAccountBasicDetailsColumn,
  advertsPrivateAccountColumn,
  advertsDealerAccountColumn,
  transactionPrivateAccountColumn,
  transactionDealerAccountColumn,
} from "../../../data/data";
import AdvertList from "./adverts";
import Transcation from "./transcation";
import { fetchView, fetchDelete } from "../../Functions/DataTable";
import { trasncationDeleteFun } from "../../../services/api";

const AccountDetails = () => {
  const [basicColumn, setBasicColumn] = React.useState([]);
  const [action, setAction] = React.useState([]);
  const [actionTrans, setActionTrans] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const privateAccountBasic = useSelector(
    (state) => state.accountDetails?.privateAccount?.basic_details
  );
  const privateAccountAdvert = useSelector(
    (state) => state.accountDetails?.privateAccount?.adverts
  );
  const dealerAccountAdvert = useSelector(
    (state) => state.accountDetails?.dealerAccount?.adverts
  );
  const privateAccounttranscation = useSelector(
    (state) => state.accountDetails?.privateAccount?.transcation
  );
  const dealerAccounttranscation = useSelector(
    (state) => state.accountDetails?.dealerAccount?.transcation
  );
  const dealerAccountBasic = useSelector(
    (state) => state.accountDetails?.dealerAccount?.basic_details
  );
  console.log("privateAccount", privateAccountAdvert);
  console.log("dealerAccount", dealerAccountAdvert);
  const urlString = window.location.href;
  const urlSegments = urlString.split("/");
  const lastItem = urlSegments[urlSegments.length - 1];
  const body = {
    id: lastItem,
  };
  React.useEffect(() => {
    dispatch(getAccountDetails(body));
    fetchActionsAdvert();
    fetchActionsTrans();
  }, []);
  const [active, setActive] = React.useState("private");
  const switchButton = (activePath) => {
    setActive(activePath);
    switch (activePath) {
      case "private":
        setBasicColumn(privateAccountBasicDetailsColumn);
        break;
      case "dealer":
        setBasicColumn(dealerAccountBasicDetailsColumn);
        break;
    }
  };

  const activeBasicDetails =
    active === "private" ? privateAccountBasic : dealerAccountBasic;
  const activeAdvertColumn =
    active == "private"
      ? advertsPrivateAccountColumn
      : advertsDealerAccountColumn;
  const activeAdvertRows =
    active == "private"
      ? privateAccountAdvert?.data
      : dealerAccountAdvert?.data;
  const activeTranscationColumns =
    active == "private"
      ? transactionPrivateAccountColumn
      : transactionDealerAccountColumn;
  const activeTranscationRows =
    active == "private"
      ? privateAccounttranscation?.data
      : dealerAccounttranscation?.data;
  const rowInformationAdvert =
    active == "private"
      ? [
          { count: privateAccountAdvert?.total_ads, content: "Total Ads" },
          { count: privateAccountAdvert?.live_ads, content: "live Ads" },
          { count: privateAccountAdvert?.sold, content: "Sold" },
          { count: privateAccountAdvert?.paused, content: "Paused" },
          { count: privateAccountAdvert?.deleted, content: "Deleted" },
        ]
      : [
          { count: dealerAccountAdvert?.total_ads, content: "Total Ads" },
          { count: dealerAccountAdvert?.live_ads, content: "live Ads" },
          { count: dealerAccountAdvert?.sold, content: "Sold" },
          { count: dealerAccountAdvert?.paused, content: "Paused" },
          { count: dealerAccountAdvert?.deleted, content: "Deleted" },
        ];
  const rowInformationTranscation =
    active == "private"
      ? [
          {
            count: privateAccounttranscation?.successful_transaction,
            content: "Successful Transcation",
          },
          {
            count: privateAccounttranscation?.failed_transaction,
            content: "Failed Transcation",
          },
        ]
      : [
          {
            count: dealerAccounttranscation?.successful_transaction,
            content: "Successful Transcation",
          },
          {
            count: dealerAccounttranscation?.failed_transaction,
            content: "Failed Transcation",
          },
        ];

  const data =
    activeTranscationRows &&
    activeTranscationRows.map((item) => {
      return {
        ...item,
        style: item.status == "Success" ? { color: "green" } : { color: "red" },
      };
    });

  const fetchActionsAdvert = async () => {
    const edit = fetchView(
      "Edit",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const viewNoraml = fetchView(
      "View",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const deleted = fetchDelete(
      "Delete",
      trasncationDeleteFun,
      dispatch,
      "",
      getAccountDetails
    );
    setAction([viewNoraml, edit, deleted]);
  };
  const fetchActionsTrans = async () => {
    const edit = fetchView(
      "Edit",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const viewNoraml = fetchView(
      "View",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const deleted = fetchDelete(
      "Delete",
      trasncationDeleteFun,
      dispatch,
      "",
      getAccountDetails
    );
    setActionTrans([viewNoraml, edit, deleted]);
  };
  return (
    <div class="overview account_details">
      <h2>{"Account details"}</h2>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <TabNavItem
          parentStyle={"nav-item"}
          childStyle={`nav-item ${!active && "active"} ${
            active == "private" && "active"
          }`}
          onClick={() => switchButton("private")}
          title={"Private Account"}
        />
        <TabNavItem
          parentStyle={"nav-item"}
          childStyle={`nav-item ${active == "dealer" && "active"}`}
          onClick={() => switchButton("dealer")}
          title={"Dealer Account"}
        />
      </ul>
      <div class="tab-content" id="myTabContent">
        <BasicDetails
          heading={"Basic Details"}
          column={
            basicColumn.length > 0
              ? basicColumn
              : privateAccountBasicDetailsColumn
          }
          row={activeBasicDetails || []}
          parentStyle={"set_list"}
          childStyle={"col-6 col-lg-4 col-xl-3 mb-4"}
        />
        <AdvertList
          columns={activeAdvertColumn}
          rows={activeAdvertRows || []}
          rowInformation={rowInformationAdvert}
          action={action}
        />
        <Transcation
          columns={activeTranscationColumns}
          rows={data || []}
          rowInformation={rowInformationTranscation}
          action={actionTrans}
        />
      </div>
    </div>
  );
};

export default AccountDetails;
