import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addCategory,
  editCategoriesFun,
  getAllCategoriesFun,
} from "../../services/api";

export const addCategories = createAsyncThunk(
  "/addcategory",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addCategory(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const addSubCategories = createAsyncThunk(
  "/addsubcategory",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addCategory(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getCategories = createAsyncThunk(
  "/getcategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getAllCategoriesFun(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "/getsubcategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getAllCategoriesFun(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editCategories = createAsyncThunk(
  "editcategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editCategoriesFun(data.id, data.body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editSubCategories = createAsyncThunk(
  "editsubcategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editCategoriesFun(data.id, data.body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    all_categories: [],
    sub_categories: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addCategories.fulfilled, (state) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(addCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.all_categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getSubCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.sub_categories = action.payload;
      })
      .addCase(getSubCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(editCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(editCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(addSubCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addSubCategories.fulfilled, (state) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(addSubCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(editSubCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editSubCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(editSubCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default categorySlice.reducer;
