import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addSubscriptionPlan, editSubscriptionPlan, getSubscriptions } from "../../services/api";

export const getAllSubPlans = createAsyncThunk("/plans",async(_,{rejectWithValue})=>{
    try {
        const response = await getSubscriptions();
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response)
    }
   
})

export const addSubPlan = createAsyncThunk("/add-plan",async(formData,{rejectWithValue})=>{
    try {
        const response = await addSubscriptionPlan(formData);
        return response;
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const editSubPlan = createAsyncThunk("edit/plan",async(data,{rejectWithValue})=>{
    try {
        const response = await editSubscriptionPlan(data.id,data.formData)
        return response;
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
const subscriptionSlice = createSlice({
    name:"subscription",
    initialState:{
        success:false,
        submitting:false,
        error:null,
        all_plans:[]
    },
    reducers:{},
    extraReducers:(builder)=>
    builder.addCase(getAllSubPlans.pending,(state)=>{
        state.submitting = true;
        state.error = null;
        state.success= false;
    })
    .addCase(getAllSubPlans.fulfilled,(state,action)=>{
        state.submitting = false;
        state.error= null;
        state.all_plans=action.payload;
        state.success = true;
    })
    .addCase(getAllSubPlans.rejected,(state,action)=>{
        state.submitting=false;
        state.error=action.payload;
        state.success=false;
    })
    .addCase(addSubPlan.pending,(state)=>{
        state.submitting=true;
        state.success = false;
        state.error=null;
    })
    .addCase(addSubPlan.fulfilled,(state,action)=>{
        state.submitting=false;
        state.success=true;
        state.error=null;
    })
    .addCase(addSubPlan.rejected,(state,action)=>{
        state.submitting=false;
        state.success=true;
        state.error=action.payload;
    })
    .addCase(editSubPlan.pending,(state,action)=>{
        state.submitting=true;
        state.success = false;
        state.error = null;
    })
    .addCase(editSubPlan.fulfilled,(state,action)=>{
        state.submitting=false;
        state.error = null;
        state.success=true;
    })
    .addCase(editSubPlan.rejected,(state,action)=>{
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
    })
})

export default subscriptionSlice.reducer;
