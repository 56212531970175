import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BoostrapModel from "../../Tools/BoostrapModel";
import AddSpecialization from "./addSpecialization";
import BasicTable from "../../Tools/Table";
import { useDispatch, useSelector } from "react-redux";
import { getSpecializationFun } from "../../../Reducer/Specialization/specializationSlice";
import { specializationColumn } from "../../../data/data";
import EditSpecialization from "./editSpecialization";

const Specialization = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [idd, setIdd] = useState("");
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const { error, success, specializations } = useSelector(
    (state) => state.specialization
  );

  useEffect(() => {
    dispatch(getSpecializationFun());
  }, [dispatch]);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        setIdd(row.id);
        setName(row.name);
        setEditShow(!editShow);
      },
      type: "text",
    },
  ];
  return (
    <div className="dashnboard pricing specialization">
      <h3>
        {"Specialization"}
        <Link className="freeze_btn" onClick={() => setShow(true)}>
          {" Add Specialization "}
        </Link>
      </h3>
      <BoostrapModel
        show={show}
        heading={"Add Specialization"}
        component={<AddSpecialization onHide={() => setShow(!show)} />}
        onHide={() => setShow(false)}
      />
      <BoostrapModel
        show={editShow}
        heading={"Edit Specialization"}
        component={
          <EditSpecialization
            onHide={() => setEditShow(!editShow)}
            id={idd}
            name={name}
          />
        }
        onHide={() => setEditShow(!editShow)}
      />
      <div>
        <BasicTable
          rows={specializations}
          columns={specializationColumn}
          actions={actions}
        />
      </div>
    </div>
  );
};

export default Specialization;
