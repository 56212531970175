import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RouteWebPage from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <RouteWebPage />
      </BrowserRouter>
    </div>
  );
}

export default App;
