import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./Reducer/user/userSlice";
import dashboardSlice from "./Reducer/dashboard/dashboardSlice";
import pagesSlice from "./Reducer/pages/pagesSlice";
import blockWordsSlice from "./Reducer/blockWords/blockWordsSlice";
import singlePendingApprovalSlice from "./Reducer/user/singlePendingApprovalSlice";
import editpageSlice from "./Reducer/pages/editpageSlice";
import userAccountSlice from "./Reducer/userAccount/userAccountSlice";
import pricingSlice from "./Reducer/pricing/pricingSlice";
import accountDetailsSlice from "./Reducer/userAccount/accountDetailsSlice";
import sendNotificationSlice from "./Reducer/sendNotification/sendNotificationSlice";
import bannerImagesSlice from "./Reducer/bannerImages/bannerImagesSlice";
import profileSilce from "./Reducer/profileSilce/profileSilce";
import authSlice from "./Reducer/auth/authSlice";
import branchSlice from "./Reducer/branch/branchSlice";
import subscriptionSlice from "./Reducer/subscription/subscriptionSlice";
import specializationSlice from "./Reducer/Specialization/specializationSlice";
import pendingRequestSlice from "./Reducer/PendingRequest/pendingRequestSlice";
import selectionSlice from "./Reducer/selection/selectionSlice";
import serviceLocationSlice from "./Reducer/serviceLocation/serviceLocationSlice";
import blogSlice from "./Reducer/blogs/blogSlice";
import categorySlice from "./Reducer/category/categorySlice";
import helpSlice from "./Reducer/Help/helpSlice";

const rootReducer = combineReducers({
  user: userSlice,
  dashboard: dashboardSlice,
  pages: pagesSlice,
  blockWords: blockWordsSlice,
  singlePendingApproval: singlePendingApprovalSlice,
  editPage: editpageSlice,
  userAccountList: userAccountSlice,
  pricing: pricingSlice,
  accountDetails: accountDetailsSlice,
  sendNotificationusers: sendNotificationSlice,
  bannerImages: bannerImagesSlice,
  profile: profileSilce,
  auth: authSlice,
  branch: branchSlice,
  subscription: subscriptionSlice,
  specialization: specializationSlice,
  pending_request: pendingRequestSlice,
  selection: selectionSlice,
  serviceLocation: serviceLocationSlice,
  blogs: blogSlice,
  category: categorySlice,
  help: helpSlice,
});

export default rootReducer;
