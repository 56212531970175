import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProfiledata } from '../../services/api';

export const myprofile = createAsyncThunk('/profile', async () => {
    try {
      const response = await getProfiledata();
      return response.data;
    } catch (error) {
      console.log("error", error)
    }
  });

const profile = createSlice({
  name: 'profile',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    users:[],
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(myprofile.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(myprofile.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.users=action.payload;
      })
      .addCase(myprofile    .rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default profile.reducer;

