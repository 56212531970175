import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  BaseUrl,
  branchColumn,
  businessUserColumn,
  frontUrl,
  locationColumn,
  logsColumn,
  rechargeColumn,
  searchColumn,
  singlePendingApprovalUser,
  staffColumn,
  status,
} from "../../../data/data";
import { Link, useParams } from "react-router-dom";
import {
  getBusinessUserProfile,
  getChildBranches,
  getLocation,
  getRecharges,
  getSearchLogs,
  getStaff,
  getUserLogs,
} from "../../../Reducer/user/userSlice";
import { Button, Card, Modal } from "react-bootstrap";
import BasicTable from "../../Tools/Table";
import Block from "./Block";
import { editPrimaryDetails } from "../../../Reducer/branch/branchSlice";
import FormGroupOption from "../../Tools/FormGroupOption";
import { changeStatus } from "../../../services/api";

const BusinessProfile = () => {
  const { id } = useParams();
  const {
    success,
    submitting,
    error,
    partner_profile,
    child_branches,
    location,
    search,
    staff,
    recharges,
    logs,
  } = useSelector((state) => state.user);
  const [primaryDetails, setPrimaryDetails] = useState({});
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const imageObject = [
    "pan_card",
    "gst_certificate",
    "authorize_certificate",
    "company_logo",
    "goverment_authorize_image",
    "banner_image",
    "profile_image",
  ];

  const infoObj = {};
  const imgObj = {};

  useEffect(() => {
    dispatch(getBusinessUserProfile(id));
    dispatch(getUserLogs(id));
  }, [submitTrigger]);

  useEffect(() => {
    setPrimaryDetails({
      owner_name_status: partner_profile.owner_name_status || "Pending",
      owner_name_comment: partner_profile.owner_name_comment,
      company_name_comment: partner_profile.company_name_comment || "",
      company_name_status: partner_profile.company_name_status || "Pending",
      company_logo_status: partner_profile.company_logo_status || "Pending",
      company_logo_comment: partner_profile.company_logo_comment,
      address_comment: partner_profile.address_comment,
      address_status: partner_profile.address_status || "Pending",
      authorize_certificate_comment:
        partner_profile.authorize_certificate_comment,
      authorize_certificate_status:
        partner_profile.authorize_certificate_status || "Pending",
      authorize_expiery_comment: partner_profile.authorize_expiery_comment,
      authorize_expiery_status:
        partner_profile.authorize_expiery_status || "Pending",
      gst_comment: partner_profile.gst_comment,
      gst_status: partner_profile.gst_status || "Pending",
      pan_comment: partner_profile.pan_comment || "",
      pan_status: partner_profile.pan_status || "Pending",
      meta_title: partner_profile.meta_title || "",
      meta_description: partner_profile.meta_description || "",
      meta_keywords: partner_profile.meta_keywords || "",
    });
  }, [partner_profile]);

  const splitObj = () => {
    Object.keys(partner_profile)?.map((key) => {
      if (imageObject.includes(key)) {
        imgObj[key] = partner_profile[key];
      } else {
        infoObj[key] = partner_profile[key];
      }
    });
  };
  splitObj();

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  const handlePrimaryDetails = (name, value) => {
    setPrimaryDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleSubmit = async () => {
    const body = { id: id, data: primaryDetails };
    dispatch(editPrimaryDetails(body));
  };

  useEffect(() => {
    if (submitTrigger) {
      handleSubmit();
      dispatch(getBusinessUserProfile(id));
    }
    setSubmitTrigger(false);
  }, [submitTrigger]);

  const handleStatus = async (e) => {
    let data = { user_id: id, value: e.value };
    try {
      let responce = await changeStatus(data);
      console.log(responce);
    } catch (error) {
      alert(error.message);
    }
  };

  const onHide = () => {
    setShow(!show);
  };

  console.log(partner_profile, "partner_profile");
  return (
    <div className="dt-table admin">
      <Card>
        <div className="worked mt-0 single_profile_freelancers">
          <div className="container_fluid"></div>
        </div>
        <div
          className="userdiv userdivsing_pro mb-3"
          style={{ backgroundImage: "url(images/user.png)" }}
        >
          <div className="row">
            <div className="col-sm-3">
              <div className="img w-100">
                <img
                  className="w-100"
                  src={`${frontUrl}/${partner_profile.profile_image}`}
                  alt="userimg"
                />
              </div>
            </div>
            <div className="col-sm-9 text-start">
              <h2 className="pt-2 pb-2">
                {partner_profile.name}
                <Link to={`/edit-branch/${id}`} className="edit">
                  Edit
                </Link>
              </h2>
              <div className="col-sm-8">
                <div>
                  {Object.keys(infoObj).map((item) => (
                    <div className="col-sm-8">
                      <p>
                        <span>{item}</span>:{partner_profile[item]}
                      </p>
                    </div>
                  ))}
                  <Block
                    label={"Company Name"}
                    image={false}
                    statuskey={"company_name_status"}
                    value={partner_profile.company_name}
                    status={partner_profile.company_name_status}
                    commentkey={"company_name_comment"}
                    id={id}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                  />
                  <Block
                    label={"Address"}
                    image={false}
                    id={id}
                    statuskey={"address_status"}
                    value={partner_profile.permanent_address}
                    status={partner_profile.address_status}
                    commentkey={"address_comment"}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                  />
                  <Block
                    label={"Owner Name"}
                    id={id}
                    image={false}
                    statuskey={"owner_name_status"}
                    value={partner_profile.owner_name}
                    status={partner_profile.owner_name_status}
                    commentkey={"owner_name_comment"}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                  />
                  <Block
                    label={"Company Logo"}
                    image={true}
                    id={id}
                    statuskey={"company_logo_status"}
                    value={partner_profile.company_logo}
                    status={partner_profile.company_logo_status}
                    commentkey={"company_logo_comment"}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                  />
                  <Block
                    label={"Gst Certificate"}
                    image={true}
                    id={id}
                    statuskey={"gst_status"}
                    value={partner_profile.gst_certificate}
                    status={partner_profile.gst_status}
                    commentkey={"gst_comment"}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                    secondLabel={"Gst Number"}
                    secondValue={partner_profile.gst_number}
                  />
                  <Block
                    label={"Authorized Certificate"}
                    id={id}
                    image={true}
                    statuskey={"authorize_certificate_status"}
                    value={partner_profile.authorize_certificate}
                    status={partner_profile.authorize_certificate_status}
                    commentkey={"authorize_certificate_comment"}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                    secondLabel={"Authorize Expiry"}
                    secondValue={partner_profile.authorize_expiery}
                  />
                  <Block
                    label={"Pan Card"}
                    id={id}
                    image={true}
                    statuskey={"pan_status"}
                    commentkey={"pan_comment"}
                    value={partner_profile.pan_card}
                    status={partner_profile.pan_status}
                    handlePrimaryDetails={handlePrimaryDetails}
                    primaryDetails={primaryDetails}
                    submit={handleSubmit}
                    setSubmitTrigger={setSubmitTrigger}
                    secondLabel={"Pan Number"}
                    secondValue={partner_profile.pan_number}
                  />

                  <div className="form-group">
                    <label>Profile Comment</label>
                    <input
                      className="form-control"
                      name={"comment"}
                      value={partner_profile.comment}
                      onChange={(e) => {
                        handlePrimaryDetails(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                  <div class={"col-sm-6 mb-3"}>
                    <div class={"form-group"}>
                      <label>Status</label>
                      <select
                        class={"form-control"}
                        name="type"
                        onChange={handleStatus}
                      >
                        {status.length > 0 &&
                          status.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  <div class={"form-group"}>
                    <label>Meta Title</label>
                    <input
                      className="form-control"
                      name="meta_title"
                      onChange={(e) =>
                        handlePrimaryDetails(e.target.name, e.target.value)
                      }
                      value={primaryDetails.meta_title}
                    />
                  </div>

                  <div class={"form-group"}>
                    <label>Meta Description</label>
                    <input
                      className="form-control"
                      name="meta_description"
                      onChange={(e) =>
                        handlePrimaryDetails(e.target.name, e.target.value)
                      }
                      value={primaryDetails.meta_description}
                    />
                  </div>
                  <div class={"form-group"}>
                    <label>Meta Keywords</label>
                    <input
                      className="form-control"
                      name="meta_keywords"
                      onChange={(e) =>
                        handlePrimaryDetails(e.target.name, e.target.value)
                      }
                      value={primaryDetails.meta_keywords}
                    />
                  </div>

                  <Button onClick={() => onHide()}>Submit</Button>
                  <Modal show={show} onHide={onHide}>
                    <Modal.Body>
                      <p>Are you sure ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          onHide();
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setSubmitTrigger(true);
                          onHide();
                        }}
                      >
                        Save changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="tab1-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab1-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab1-tab-pane"
              aria-selected="true"
              onClick={() => dispatch(getUserLogs(id))}
            >
              Logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab6-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab6-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab6-tab-pane"
              aria-selected="false"
              onClick={() => {
                dispatch(getChildBranches(id));
              }}
            >
              Branches
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab2-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab2-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab2-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getLocation(id))}
            >
              Locations
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab3-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab3-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab3-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getRecharges(id))}
            >
              Recharge logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab4-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab4-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab4-tab-pane"
              aria-selected="false"
            >
              Queries logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab5-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab5-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab5-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getSearchLogs(id))}
            >
              Search logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab7-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab7-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab7-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getStaff(id))}
            >
              Staff
            </button>
          </li>
        </ul>
        <div
          className="tab-content userdiv userdiv2 text-start"
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active"
            id="tab1-tab-pane"
            role="tabpanel"
            aria-labelledby="tab1-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={logsColumn} rows={logs} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab6-tab-pane"
            role="tabpanel"
            aria-labelledby="tab6-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable rows={child_branches} columns={branchColumn} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab2-tab-pane"
            role="tabpanel"
            aria-labelledby="tab2-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable rows={location} columns={locationColumn} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab3-tab-pane"
            role="tabpanel"
            aria-labelledby="tab3-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={rechargeColumn} rows={recharges} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab4-tab-pane"
            role="tabpanel"
            aria-labelledby="tab4-tab"
            tabIndex={0}
          >
            <div className="freelancers ">
              <div className="inset_admin p-0">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">NO</th>
                        <th scope="col">Name</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Employer 1</td>
                        <td> June 12, 2023 </td>
                        <td>10:00 - 19:00</td>
                        <td>
                          <a href="#" className="badge text-warning">
                            Pending
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="pagi">
                  <nav
                    aria-label="Page navigation example"
                    className="d-inline-block"
                  >
                    <ul className="pagination mb-0">
                      <li className="page-item me-3">
                        <a className="page-link" href="#">
                          Previous page
                        </a>
                      </li>
                      <li className="page-item num active">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          6
                        </a>
                      </li>
                      <li className="page-item ms-3">
                        <a className="page-link" href="#">
                          Next page
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab5-tab-pane"
            role="tabpanel"
            aria-labelledby="tab5-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={searchColumn} rows={search} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab7-tab-pane"
            role="tabpanel"
            aria-labelledby="tab7-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={staffColumn} rows={staff} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default BusinessProfile;
