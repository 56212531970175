import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
const UsersOption = () => {
    return (<ul>
        <li> <ChecboxWithSpan
            parentStyle={"checkcontainer"}
            childStyle={"radiobtn"}
            inType={"checkbox"}
            inputStyle={"quality"}
            name={"business_users"}
            value={"T1"} /> All business users</li>
        <li><ChecboxWithSpan
            parentStyle={"checkcontainer"}
            childStyle={"radiobtn"}
            inType={"checkbox"}
            inputStyle={"quality"}
            name={"regular_users"}
            value={"T1"} /> All regular users</li>
    </ul>)
}
export default UsersOption;