import React, { useEffect, useState } from "react";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { editorFormats, editorModules, helpStatus } from "../../../data/data";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserHelp,
  getHelpCatbyType,
  getHelpCategory,
} from "../../../Reducer/Help/helpSlice";
import FormGroupOption from "../../Tools/FormGroupOption";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AddHelp = ({ type }) => {
  const { register, handleSubmit } = useForm();
  const [answers, setAnswers] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const helpCatByType = useSelector((state) => state.help.helpCatByType);
  const success = useSelector((state) => state.help.success);

  useEffect(() => {
    dispatch(getHelpCatbyType(type));
  }, []);

  const handleAnswer = (content) => {
    setAnswers(content);
  };
  const onSubmit = (data) => {
    const body = { ...data, answers, type };
    dispatch(addUserHelp(body));
    if (type == "user") {
      navigate("/user-help");
    } else {
      navigate("/business-help");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroupLabelInput
          label={"Question"}
          name="question"
          event={register}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          placeholder={"Question"}
        />
        <div className="my-3">
          <label>Answer</label>
          <ReactQuill
            formats={editorFormats}
            modules={editorModules}
            onChange={handleAnswer}
          />
        </div>
        <div class={"col-sm-12 mb-3"}>
          <div class={"form-group"}>
            <label>Category</label>
            <select
              class={"form-control"}
              name="cat_id"
              {...register("cat_id", { required: "Category is Required" })}
            >
              {helpCatByType.length > 0 &&
                helpCatByType.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
            </select>
          </div>
        </div>
        <FormGroupOption
          label={"Status"}
          parentStyle={"col-sm-12 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          options={helpStatus}
          event={register}
          name={"status"}
        />

        <div class={"form-group"}>
          <label>{"Youtube Url"}</label>
          <input
            class={"form-control"}
            name="youtube_ref"
            {...register("youtube_ref")}
            placeholder={"Youtube Url"}
          />
        </div>
        <Button className="my-3" type="submit" disabled={answers.length == 0}>
          Add Help
        </Button>
      </form>
    </div>
  );
};

export default AddHelp;
