import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBannerImageFun } from '../../services/api';

export const getBannerImage = createAsyncThunk('/banner_images', async () => {
    try {
      const response = await getBannerImageFun();
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  });

const bannerImagesSlice = createSlice({
  name: 'bannerImages',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    bannerImages:[],
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getBannerImage.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getBannerImage.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.bannerImages=action.payload;
      })
      .addCase(getBannerImage.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default bannerImagesSlice.reducer;

