import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSingleUserPendingApprovalFun } from '../../services/api';



export const singlePendingApprovalFun = createAsyncThunk('/singlependingaprroval', async (formData, { rejectWithValue }) => {
  try {
    const response =  await getSingleUserPendingApprovalFun(formData);
    console.log("response", response);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const singlePendingApprovalSlice = createSlice({
  name: 'singlePendingApproval',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    user:[],
    singlePendingApproval: {},
    token:""
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(singlePendingApprovalFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(singlePendingApprovalFun.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.singlePendingApproval=action.payload;
      })
      .addCase(singlePendingApprovalFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default singlePendingApprovalSlice.reducer;

