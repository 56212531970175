import BasicTable from "../../Tools/Table";
const UpdateContent = ({
  heading,
  pagesColumn,
  rows,
  action,
  parentStyle,
  childStyle,
}) => {
  return (
    <div class={parentStyle}>
      <h3 class={childStyle}>{heading}</h3>
      <BasicTable columns={pagesColumn} rows={rows} actions={action} />
    </div>
  );
};

export default UpdateContent;
