import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import { addBranchForm } from "../../../Reducer/branch/branchSlice";
import moment from "moment";

const AddBranch = ({ show, setShow }) => {
  const [form, setForm] = useState({
    company_name: "",
    owner_name: "",
    phone: null,
    latitude: "",
    longitude: "",
    permanent_address: "",
    type: "",
    pan_card: "",
    authorize_expirey: "",
    company_logo: "",
    authorize_certificate: "",
    gst_certificate: "",
  });
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({});

  const dispatch = useDispatch();

  const { error, success, submitting } = useSelector((state) => state.branch);
  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => {
          console.log(placeDetails, "Place");
          setForm({
            ...form,
            latitude: placeDetails.geometry.location.lat(),
            longitude: placeDetails.geometry.location.lng(),
          });
        }
      );
  }, [placePredictions]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      setForm({ ...form, [name]: e.target.files[0] });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const onSubmitform = async (e) => {
    e.preventDefault();
    const unixTime = moment(form.authorize_expirey).unix();
    const formData = new FormData();
    formData.append("company_name", form.company_name);
    formData.append("owner_name", form.owner_name);
    formData.append("permanent_address", form.permanent_address);
    formData.append("latitude", form.latitude);
    formData.append("longitude", form.longitude);
    formData.append("type", form.type);
    formData.append("phone", form.phone);
    formData.append("authorize_expirey", unixTime);
    formData.append("company_logo", form.company_logo);
    formData.append("pan_card", form.pan_card);
    formData.append("authorize_certificate", form.authorize_certificate);
    formData.append("gst_certificate", form.gst_certificate);
    dispatch(addBranchForm(formData));
    setShow(false);
  };

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form className="form-signin">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  className={"form-control"}
                  type={"text"}
                  value={form.company_name}
                  onChange={handleChange}
                  name={"company_name"}
                  placeholder={"Enter company name"}
                />
              </div>
              <div className="form-group">
                <label>Owner Name</label>
                <input
                  className={"form-control"}
                  type={"text"}
                  value={form.owner_name}
                  onChange={handleChange}
                  name={"owner_name"}
                  placeholder={"Enter owner name"}
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  className={"form-control"}
                  type={"number"}
                  value={form.phone}
                  onChange={handleChange}
                  name={"phone"}
                  placeholder={"Enter phone number"}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  className={"form-control"}
                  type={"text"}
                  name={"permanent_address"}
                  value={form.permanent_address}
                  onChange={(e) => {
                    handleChange(e);
                    getPlacePredictions({ input: e.target.value });
                  }}
                  placeholder={"Enter Address"}
                />
                {placePredictions.map((item) => (
                  <p
                    onClick={() => {
                      setForm({
                        ...form,
                        permanent_address: item.description,
                      });
                      getPlacePredictions({ input: item.description });
                    }}
                  >
                    {item.description}
                  </p>
                ))}
              </div>
              {/* <div className="form-group">
                <label>Latitude</label>
                <input
                  className={"form-control"}
                  type={"number"}
                  name={"latitude"}
                  value={form.latitude}
                  onChange={handleChange}
                  placeholder={"Enter Latitude"}
                />
              </div> */}
              {/* <div className="form-group">
                <label>Longitude</label>
                <input
                  className={"form-control"}
                  type={"number"}
                  name={"longitude"}
                  value={form.longitude}
                  onChange={handleChange}
                  placeholder={"Enter Longitude"}
                />
              </div> */}
              <div className="form-group">
                <label>Type</label>
                <input
                  type="radio"
                  name="type"
                  value={"institute"}
                  onChange={handleChange}
                  checked={form.type == "institute"}
                />
                Institute
                <input
                  type="radio"
                  name="type"
                  value={"immigration"}
                  onChange={handleChange}
                  checked={form.type == "immigration"}
                />{" "}
                Immigration
              </div>
              <div className="form-group">
                <label>Authorize Expiry</label>
                <input
                  className="form-control"
                  type="date"
                  name="authorize_expirey"
                  value={form.authorize_expirey}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Company Logo</label>
                <input
                  className={"form-control"}
                  type={"file"}
                  name={"company_logo"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Pan Image</label>
                <input
                  className={"form-control"}
                  type={"file"}
                  name={"pan_card"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Goverment Authorize Image</label>
                <input
                  className={"form-control"}
                  type={"file"}
                  name={"authorize_certificate"}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Gst Number Certificate Image</label>
                <input
                  className={"form-control"}
                  type={"file"}
                  name={"gst_certificate"}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmitform}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddBranch;
