import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { sidebarItemMenu } from "../../../data/data";
import ListItem from "../../Tools/ListItem";
import $ from "jquery";
import { clearToken, clearUser } from "../../../Reducer/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
const Sidebar = ({ component }) => {
  const [expandedSubmenus, setExpandedSubmenus] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const logout = () => {
    dispatch(clearToken());
    dispatch(clearUser());
  };
  if (isAuthenticated == "") {
    navigate("/login");
  }

  const toggleSubmenu = (itemPath) => {
    setExpandedSubmenus((prev) => ({
      ...prev,
      [itemPath]: !prev[itemPath],
    }));
  };

  return (
    <div className="container h-100">
      <section className="dashnboard ">
        <div className="desh">
          <div className="hom d-block d-md-none">
            <span
              className="mnu"
              onClick={() => $(".sidebar").addClass("main")}
            >
              <i className="fa-solid fa-bars"></i>
            </span>
            <Link className="logo">
              <img src="../assets/images/Logo.png" />
            </Link>
          </div>
          {/* <span><h2>{activeTab ? activeTab : "Dashboard"}</h2></span> */}

          <div className="dropdown imgus">
            <button
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="h-100 w-100"
                src="../assets/images/us.png"
                alt="img"
              />{" "}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <NavLink className="dropdown-item" to="/profile">
                  My Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => logout()}
                  className="dropdown-item"
                  to="/login"
                >
                  Log out
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="sidebar">
            <div className="sid1">
              <span
                className="mnu d-block d-md-none"
                onClick={() => $(".sidebar").removeClass("main")}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
              <NavLink className={"logo"} to={"/"}>
                <img src="../assets/images/Logo.png" />
              </NavLink>

              <ul>
                {sidebarItemMenu.map((item) => (
                  <ListItem
                    innerhtmlType={"html"}
                    style={
                      item.submenu
                        ? ""
                        : location.pathname === item.path
                        ? "active"
                        : ""
                    }
                    value={
                      <div>
                        {item.submenu ? (
                          // Render label only if submenu is available
                          <div>
                            <span
                              onClick={() => toggleSubmenu(item.path)}
                              className="submenu-toggle"
                            >
                              {location.pathname !== item.path && (
                                <img
                                  src={item.imageOne}
                                  alt="icon"
                                  width="24px"
                                />
                              )}
                              {location.pathname === item.path && (
                                <img
                                  src={item.imageSecond}
                                  className="img2"
                                  alt="icon2"
                                  width="24px"
                                />
                              )}
                              {item.title}
                            </span>
                            {expandedSubmenus[item.path] && (
                              <ul>
                                {item.submenu.map((subItem) => (
                                  <ListItem
                                    innerhtmlType={"html"}
                                    style={
                                      location.pathname === subItem.path &&
                                      "active"
                                    }
                                    value={
                                      <Link to={subItem.path}>
                                        {location.pathname !== subItem.path && (
                                          <img
                                            src={subItem.imageOne}
                                            alt="icon"
                                          />
                                        )}
                                        {location.pathname === subItem.path && (
                                          <img
                                            src={subItem.imageSecond}
                                            className="img2"
                                            alt="icon2"
                                          />
                                        )}
                                        {subItem.title}
                                      </Link>
                                    }
                                  />
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          // Render link if submenu is not available
                          <Link to={item.path}>
                            {location.pathname !== item.path && (
                              <img src={item.imageOne} alt="icon" />
                            )}
                            {location.pathname === item.path && (
                              <img
                                src={item.imageSecond}
                                className="img2"
                                alt="icon2"
                              />
                            )}
                            {item.title}
                          </Link>
                        )}
                      </div>
                    }
                  />
                ))}
              </ul>
            </div>
          </div>
          {component}
        </div>
      </section>
    </div>
  );
};

export default Sidebar;
