import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { Button } from "react-bootstrap";
import { editCategories } from "../../../Reducer/category/categorySlice";
import { findCategoryAvail } from "../../../services/api";

const EditCategory = ({ onHide, editdata }) => {
  const [categoryStatus, setCategoryStatus] = useState([]);
  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const body = { id: editdata.id, body: { ...data, parent_id: 0 } };
    dispatch(editCategories(body));
    onHide();
  };

  useEffect(() => {
    setValue("name", editdata.name);
  }, []);

  const handleChange = async (e) => {
    try {
      const body = { id: 0, name: e.target.value };
      const data = await findCategoryAvail(body);
      setCategoryStatus(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class={"form-group"}>
          <label>{"Category Name"}</label>
          <input
            class={"form-control"}
            name="name"
            {...register("name")}
            onChange={handleChange}
          />
        </div>
        <div className="modal-footer"></div>
        <Button type="submit">Save Changes</Button>
      </form>
    </div>
  );
};

export default EditCategory;
