import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BasicTable from "../../Tools/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBlogs } from "../../../Reducer/blogs/blogSlice";
import { BaseUrl, blogsColumn } from "../../../data/data";
import BoostrapModel from "../../Tools/BoostrapModel";
import DeleteBlog from "./DeleteBlog";
const Blogs = () => {
  const [show, setShow] = useState(false);
  const [blogToDlt, setBlogToDlt] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { all_blogs } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchAllBlogs());
  }, [show, dispatch]);

  const onHide = () => setShow(!show);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        navigate(`/edit-blog/${row.slug}`);
      },
      type: "text",
    },
    {
      label: "Delete",
      style: "Reject",
      onclick: (row) => {
        setShow(true);
        setBlogToDlt(row);
      },
      type: "text",
    },
  ];

  return (
    <div>
      <h3>
        Blogs <Button onClick={() => navigate("/add-blog")}>Add Blogs</Button>
      </h3>
      <BasicTable
        rows={all_blogs ? all_blogs : []}
        columns={blogsColumn}
        actions={actions}
        url={BaseUrl}
      />
      <BoostrapModel
        show={show}
        heading={"Are You Sure?"}
        onHide={onHide}
        component={<DeleteBlog data={blogToDlt} onHide={onHide} />}
      />
    </div>
  );
};

export default Blogs;
