import React from "react";
import Button from "../../Tools/Button";
import InputEmoji from "react-input-emoji";
import FormTextArea from "../../Tools/Textarea";
import FormInput from "../../Tools/FormInput";

const NotificationMessage = ({ heading }) => {
    const [text, setText] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };
    function handleOnEnter() {
        console.log("enter", text);
    }

    return (
        <div className="setin">
            <h3>{heading}</h3>
            <FormInput intype={"text"} style={"form-control"} placeholder={"Enter the Notification Title"} />
            <br />
            <FormTextArea placeholder={"Write your notification content..."} value={text} onChange={(e) => setText(e.target.value)} />
            <label for="fileInput">
                <span>
                    <img src="assets/images/paper-clip.png" alt="img" style={{width: "20px"}} />
                </span>
            </label>
            <input type="file" id="fileInput" style={{display: "none"}} onChange={handleFileChange} />

            <InputEmoji
                value={text}
                onChange={setText}
                cleanOnEnter
                onEnter={handleOnEnter}
                placeholder="Type a message"
            />
            <form className="intype"> {/* Use className instead of class */}
                <Button type={"submit"} title={<img src="assets/images/send.png" alt="img" />} />
            </form>
        </div>
    );
};

export default NotificationMessage;
