import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading"
import BasicTable from "../../Tools/Table"

const AdvertList = ({ rows, columns, rowInformation, action }) => {
    console.log("avertinformation", rowInformation)
    return (<div class="set_list2">
        <h3>
            {"Adverts"}
            <ul>
                <li>
                    <select class="form-control">
                        <option>All status</option>
                        <option>Status</option>
                        <option>Status2</option>
                    </select>
                </li>
                <li>
                    <HeadingWithDatePicker image={"../assets/images/calendar.png"} />
                </li>
            </ul>
        </h3>
        <BasicTable columns={columns} rows={rows} itemperpage={10} rowInformation={rowInformation} actions={action} actionType={"dropdown"} dropDownImage={"../assets/images/dash_img/threedot.png"} />
        </div>)
}
export default AdvertList