import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSubCategories } from "../../../Reducer/category/categorySlice";
import BasicTable from "../../Tools/Table";
import { categoryColumn } from "../../../data/data";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditSubCategory from "./EditSubCategory";

const SubCategory = () => {
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const { success, submitting, error, sub_categories } = useSelector(
    (state) => state.category
  );
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSubCategories(id));
  }, []);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        console.log(row);
        setShow(true);
        setEditData(row);
      },
      type: "text",
    },
  ];

  const onHide = () => {
    setShow(false);
  };

  return (
    <div>
      <h3>Sub Category </h3>
      <BasicTable
        rows={sub_categories ? sub_categories : []}
        columns={categoryColumn}
        // actions={actions}
      />
      {/* <BoostrapModel
        show={show}
        onHide={onHide}
        heading={"Edit Sub Category"}
        component={<EditSubCategory onHide={onHide} editdata={editData} />}
      /> */}
    </div>
  );
};

export default SubCategory;
