import React from "react"; // Import React instead of React namespace
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import MiniBox from "../MiniBox";
import { BaseUrl } from "../../../data/data";
import ReactDropdown from "react-dropdown";
import "react-dropdown/style.css";
import { changeStatus } from "../../../services/api";

export default function BasicTable({
  itemperpage,
  columns,
  rows,
  url,
  actions,
  view,
  actionType,
  rowInformation,
  dropDownImage,
  status,
  businessUserStatus,
}) {
  // Corrected parameter names

  // Pagination state
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = itemperpage ? itemperpage : 10; // Change this to the desired number of items per page

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const boxes = rows.length / itemsPerPage;
  let buttonArray = [];
  for (let i = 1; i <= Math.ceil(boxes); i++) {
    buttonArray.push(i);
  }

  const onSelect = async (e, id) => {
    let data = { user_id: id, value: e.value };
    try {
      let responce = await changeStatus(data);
      console.log(responce);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.length > 0 &&
                columns.map((item, index) =>
                  index === 0 ? (
                    <TableCell key={index} scope="col">
                      <strong>{item.label}</strong>
                    </TableCell>
                  ) : (
                    <TableCell scope="col" align="right" key={index}>
                      <strong>{item.label}</strong>
                    </TableCell>
                  )
                )}
              {view && <TableCell>{"View "}</TableCell>}
              {status && <TableCell>{"Status"}</TableCell>}
              {businessUserStatus && <TableCell>{"Status"}</TableCell>}
              {actions && actions.length > 0 && (
                <TableCell>{"Actions"}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.slice(startIndex, endIndex).map(
                (
                  row,
                  rowIndex // Renamed index to rowIndex
                ) => (
                  <TableRow
                    key={rowIndex} // Use a unique key for each row
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((item, columnIndex) =>
                      columnIndex === 0 ? (
                        <TableCell component="th" scope="row">
                          {item.navigate ? (
                            <Link
                              style={{ textDecoration: "none", color: "black" }}
                              to={`${item.path}/${row.id}`}
                            >
                              {row[item.name]}
                            </Link>
                          ) : (
                            row[item.name]
                          )}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="right"
                          style={item.name == "status" ? row.style : {}}
                        >
                          {item.navigate ? (
                            <Link
                              style={{ textDecoration: "none", color: "black" }}
                              to={`${item.path}/${row.id}`}
                            >
                              {row[item.name]}
                            </Link>
                          ) : item.type == "image" ? (
                            <span class="img">
                              <img
                                className={item.style}
                                height="50px"
                                src={`${url}/${row[item.name]}`}
                              />
                            </span>
                          ) : (
                            row[item.name]
                          )}{" "}
                          {/* Convert the object to a string */}
                        </TableCell>
                      )
                    )}
                    {view && (
                      <TableCell>
                        <Link className="view" to={`${view.path}/${row.id}`}>
                          <img
                            src={`assets/images/${view.image}`}
                            width="30px"
                            alt="icon"
                          />
                          {view.label}
                        </Link>
                      </TableCell>
                    )}
                    {status && (
                      <TableCell>
                        <ReactDropdown
                          options={status}
                          onChange={(e) => onSelect(e, row.id)}
                          id={row.id}
                          value={row.status}
                        />
                      </TableCell>
                    )}
                    {businessUserStatus && (
                      <TableCell>
                        <ReactDropdown
                          options={businessUserStatus}
                          onChange={(e) => onSelect(e, row.uid)}
                          id={row.id}
                          value={row.status}
                        />
                      </TableCell>
                    )}
                    {actionType == "dropdown" &&
                      actions &&
                      actions.length > 0 && (
                        <TableCell>
                          <DropdownButton
                            className="btn"
                            title={
                              <img
                                src={
                                  dropDownImage
                                    ? dropDownImage
                                    : "assets/images/dash_img/threedot.png"
                                }
                                alt="icon"
                              />
                            }
                          >
                            {actions.map((actionItem, actionIndex) => (
                              <Dropdown.Item
                                key={actionIndex}
                                onClick={() => actionItem.onclick(row.id)}
                              >
                                {actionItem.label}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </TableCell>
                      )}
                    {!actionType && actions && actions.length > 0 && (
                      <TableCell>
                        {actions.map((actionItem, actionIndex) => (
                          <a
                            class={actionItem.style}
                            style={actionItem.inlineStyle}
                            key={actionIndex}
                            onClick={() => actionItem.onclick(row)}
                          >
                            {actionItem.type != "text" && (
                              <img
                                src={`../assets/images/${actionItem.image}`}
                                alt="image"
                              />
                            )}
                            {actionItem.label}
                          </a>
                        ))}
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {rowInformation && rowInformation.length > 0 && (
        <div className="indiv">
          {rowInformation.map((item, index) => (
            <MiniBox
              key={index}
              style="st"
              heading={item.count || ""}
              paragraph={item.content}
            />
          ))}
        </div>
      )}
      <div class="text-right">
        <nav aria-label="Page navigation example">
          <ul class="pagination mb-0 float-end">
            <li
              class="page-item"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <a class="page-link">
                <i class="fa-solid fa-chevron-left"></i>
              </a>
            </li>
            {buttonArray.map((item, index) =>
              currentPage == index + 1 ? (
                <li class="page-item">
                  <a
                    class="page-link active"
                    onClick={() => setCurrentPage(item)}
                  >
                    {item}
                  </a>
                </li>
              ) : (
                <li class="page-item">
                  <a class="page-link" onClick={() => setCurrentPage(item)}>
                    {item}
                  </a>
                </li>
              )
            )}
            <li
              class="page-item"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <a class="page-link">
                <i class="fa-solid fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
