import { BaseUrl } from "../../../data/data";
import Button from "../Button";
const SubscriptionBox = ({ parentSTyle, childStyle, style, onClick, item }) => {
  return (
    <div class={parentSTyle}>
      <div class={childStyle}>
        <div class={`hd ${item.title == "Premium" && "pr"}`} style={style}>
          <h4>{item.title}</h4>
          <h5>{item.duration}</h5>
        </div>
        <h4>${item.amount}</h4>
        <ul>
          <li>{item.description}</li>
          <li>Call Type:{item.call_type}</li>
          <li>Chat Type:{item.chat_type}</li>
          <li>Chat Limit:{item.chat_limit}</li>
          <li>Call Limit:{item.call_limit}</li>
          <li>Analytics:{item.analytics}</li>
          <li>Branch Count:{item.branch_count}</li>
          <li>Staff Per Branch:{item.staff_per_branch}</li>
          <li>First Priority:{item.priority}</li>
          <li>Service Fees Per Application:{item.service_fees}</li>
        </ul>
        <img src={`${BaseUrl}/${item.image}`} alt="" />
        <Button
          style={"edit_plan"}
          title={"Edit Plan"}
          onClick={() => onClick(item.id)}
        />
      </div>
    </div>
  );
};

export default SubscriptionBox;
