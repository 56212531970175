import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updatePendingRequest } from "../../../Reducer/PendingRequest/pendingRequestSlice";
import { BaseUrl } from "../../../data/data";
import { FcApproval, FcDisapprove } from "react-icons/fc";

const Block = ({
  label,
  value,
  image,
  statuskey,
  status,
  commentkey,
  addData,
  setSubmitTrigger,
}) => {
  const [show, setShow] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    addData(name, value);
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    addData(name, value);
  };

  const onHide = () => setShow(!show);
  return (
    <div className="block-box">
      {!image ? (
        <h5>
          <span>
            {label}:{value}
          </span>
        </h5>
      ) : (
        <label>
          {label} : <img src={`${BaseUrl}/${value}`} alt={label} />
        </label>
      )}
      {status == "Pending" ? (
        <>
          <div className="form-group">
            <label>Comment</label>
            <input
              className="form-control"
              name={commentkey}
              onChange={handleChange}
            />
          </div>

          <Button
            type="submit"
            variant="success"
            name={statuskey}
            value={"Approved"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Approved
          </Button>
          <Button
            type="submit"
            variant="danger"
            name={statuskey}
            value={"Rejected"}
            onClick={(e) => {
              handleClick(e);
              onHide();
            }}
          >
            Rejected
          </Button>
        </>
      ) : status == "Approved" ? (
        <FcApproval />
      ) : (
        <FcDisapprove />
      )}

      <Modal show={show} onHide={onHide}>
        <Modal.Body>
          <p>Are you sure ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onHide();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setSubmitTrigger(true);
              onHide();
            }}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Block;
