export const transactionData = [
  {
    id: 1,
    email: "sahilpatel6@gmail.com",
    materialType: "Plastic",
    weight: "120 kg",
    price: "Rs 30/kg",
    totalAmount: "3600",
  },
  {
    id: 1,
    email: "test2@gmail.com",
    materialType: "Plastic",
    weight: "121 kg",
    price: "Rs 30/kg",
    totalAmount: "3600",
  },
  {
    id: 1,
    email: "test3@gmail.com",
    materialType: "Plastic",
    weight: "110 kg",
    price: "Rs 30/kg",
    totalAmount: "1500",
  },
  {
    id: 1,
    email: "seconemail@gmail.com",
    materialType: "Plastic",
    weight: "100 kg",
    price: "Rs 30/kg",
    totalAmount: "2000",
  },
  {
    id: 1,
    email: "helloworld@gmail.com",
    materialType: "Plastic",
    weight: "90 kg",
    price: "Rs 30/kg",
    totalAmount: "2500",
  },
];

export const BaseUrl = "https://apiofficeback.chownk.in";
export const frontUrl = "https://apiofficefront.chownk.in";
export const status = [
  "hold",
  "active",
  "reject",
  "block",
  "deactivate",
  "objection",
  "uncompleted",
];
export const headerWithDatePickerData = [
  "This week",
  "This month",
  "This year",
];

export const sidebarItemMenu = [
  {
    active: 1,
    imageOne: "../assets/images/dash_img/view-grid.png",
    imageSecond: "../assets/images/dash_img/view-grid_C.png",
    title: "Dashboard",
    path: "/",
  },
  {
    active: 2,
    imageOne: "../assets/images/user.png",
    imageSecond: "../../assets/images/dash_img/user_C.png",
    title: "Users",
    path: "users",
    submenu: [
      {
        active: 1,
        imageOne: "../assets/images/user.png",
        imageSecond: "../assets/images/dash_img/user_C.png",
        title: "Customer",
        path: "/user_accounts",
      },
      {
        active: 2,
        imageOne: "../assets/images/dash_img/check-circle.png",
        imageSecond: "../assets/images/dash_img/check-circle_C.png",
        title: "Business User",
        path: "/business-users",
        submenu: [
          {
            active: 1,
            imageOne: "../assets/images/dash_img/check-circle.png",
            imageSecond: "../assets/images/dash_img/check-circle_C.png",
            title: "All business User",
            path: "/business-users",
          },
        ],
      },
    ],
  },
  {
    active: 3,
    imageOne: "../assets/images/dash_img/check-circle.png",
    imageSecond: "../assets/images/dash_img/check-circle_C.png",
    title: "Subscription Plans",
    path: "/pricing_controls",
  },
  {
    active: 4,
    imageOne: "../assets/images/dash_img/file.png",
    imageSecond: "../assets/images/dash_img/file(1).png",
    title: "Business Request Pending",
    path: "/pending-requests",
  },
  {
    active: 5,
    imageOne: "../assets/images/dash_img/location.png",
    imageSecond: "../assets/images/dash_img/location1.png",
    title: "Service Location",
    path: "/service-location",
  },
  {
    active: 6,
    imageOne: "../assets/images/dash_img/location.png",
    imageSecond: "../assets/images/dash_img/location1.png",
    title: " Help",
    path: "/help",
    submenu: [
      {
        active: 1,
        imageOne: "../assets/images/dash_img/file.png",
        imageSecond: "../assets/images/dash_img/file(1).png",
        title: "Help Categories",
        path: "/help-category",
      },
      {
        active: 2,
        imageOne: "../assets/images/dash_img/file.png",
        imageSecond: "../assets/images/dash_img/file(1).png",
        title: "User Help",
        path: "/user-help",
      },
      {
        active: 3,
        imageOne: "../assets/images/dash_img/file.png",
        imageSecond: "../assets/images/dash_img/file(1).png",
        title: "Business Help",
        path: "/business-help",
      },
    ],
  },
  {
    active: 7,
    imageOne: "../../assets/images/dash_img/setting.png",
    imageSecond: "../../assets/images/dash_img/setting(1).png",
    title: " Settings",
    path: "setting",
    submenu: [
      {
        active: 1,
        imageOne: "../assets/images/dash_img/badge(1).png",
        imageSecond: "../assets/images/dash_img/badge.png",
        title: "Specialization",
        path: "/specialization",
      },
      {
        active: 2,
        imageOne: "../assets/images/dash_img/file.png",
        imageSecond: "../assets/images/dash_img/file(1).png",
        title: "Document Selection",
        path: "/document-selection",
      },
      {
        active: 3,
        imageOne: "../assets/images/dash_img/document.png",
        imageSecond: "../assets/images/dash_img/document_C.png",
        title: "Blogs",
        path: "/blogs",
      },
      {
        active: 4,
        imageOne: "../assets/images/dash_img/document.png",
        imageSecond: "../assets/images/dash_img/document_C.png",
        title: "Categories",
        path: "/categories",
      },
    ],
  },
  {
    active: 8,
    imageOne: "../assets/images/dash_img/view-grid.png",
    imageSecond: "../assets/images/dash_img/view-grid_C.png",
    title: "Add Expert",
    path: "/add-expert",
  },
];

export const keyStatsPosignData = [
  {
    title: "155",
    description: "Basic 2 weeks ad",
  },
  {
    title: "145",
    description: "Basic 3 weeks ad",
  },
  {
    title: "155",
    description: "Basic 4 weeks ad",
  },
  {
    title: "135",
    description: "Basic 6 weeks ad",
  },
];

export const metricsData = [
  {
    title: "451",
    description: "Views on selling cars",
  },
  {
    title: "500",
    description: "Views on rental cars",
  },
  {
    title: "500",
    description: "Views on checking cars",
  },
  {
    title: "3200",
    description: "Views on website",
  },
  {
    title: "$25,0000",
    description: "Views on app",
  },
  {
    title: "500",
    desctiption: "Total views",
  },
];

export const overviewData = [
  {
    title: "500",
    description: "Total active ads",
  },
  {
    title: "500",
    description: "Total users",
  },
  {
    title: "500",
    description: "Business users",
  },
  {
    title: "500",
    description: "Individual users",
  },
  {
    title: "$25,0000",
    description: "Total payments received",
  },
  {
    title: "500",
    description: "Total Views",
  },
];
export const businessUserColumn = [
  {
    label: "Uid",
    name: "uid",
  },
  {
    label: "Phone",
    name: "phone",
  },
  {
    label: "Account Type",
    name: "type",
  },
  {
    label: "Profile Image",
    name: "profile_image",
    type: "image",
  },

  {
    label: "Online",
    name: "online",
  },
  {
    label: "Address",
    name: "current_address",
  },
];

export const pagesColumn = [
  {
    label: "PAGE NAME",
    name: "title",
  },
  {
    label: "LAST UPDATED",
    name: "last_updated",
  },
];

export const blockWordsColumn = [
  {
    label: "Words",
    name: "words",
  },
  {
    label: "Date",
    name: "date",
  },
  // {
  //     label: "Action",
  //     name: "action"
  // },
];

export const singlePendingApprovalUser = [
  {
    label: "Owner Name",
    name: "owner_name",
  },
  {
    label: "Company Name",
    name: "company_name",
  },
  {
    label: "Username",
    name: "username",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Phone",
    name: "phone",
  },
  {
    label: "Address",
    name: "address",
  },
];
export const statusOptions = ["active", "hold"];

export const userAccountColumns = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "Phone Number",
    name: "phone",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Account Type",
    name: "type",
  },
  {
    label: "Profile Image",
    name: "profile_image",
    type: "image",
    style: "h-50px",
  },
  {
    label: "Online",
    name: "online",
  },
  {
    label: "Address",
    name: "current_address",
  },
];

export const deleteOption = [
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
];
export const issueOption = [
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
];
export const refundOption = [
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
  "Select a reason",
];

export const editSubscriptonPrice = ["free", "paid"];
export const editSubscriptonDiscount = ["0%", "1%", "2%"];
export const editSubscriptonStatus = ["enable", "disable"];
export const editSubscriptonChatType = [
  "Unlimited",
  "Limited",
  "Not available",
];
export const editSubscriptonDuration = ["weekly", "monthly", "annually"];
export const editSubscriptonCallType = [
  "Unlimited",
  "Limited",
  "Not available",
];
export const editSubscriptonAnalytics = ["enable", "disable"];

export const privateAccountBasicDetailsColumn = [
  {
    label: "Name",
    name: "full_name",
  },
  {
    label: "Username",
    name: "username",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Phone",
    name: "phone",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Current plan",
    name: "current_plan",
  },
];

export const dealerAccountBasicDetailsColumn = [
  {
    label: "Name",
    name: "full_name",
  },
  {
    label: "Username",
    name: "username",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Phone",
    name: "phone",
  },
  {
    label: "City",
    name: "city",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Current plan",
    name: "current_plan",
  },
  {
    label: "About",
    name: "about",
    style: "col-12 mb-4",
  },
];

export const advertsPrivateAccountColumn = [
  {
    label: "DATE POSTED",
    name: "date",
  },
  {
    label: "IMPRESSION",
    name: "imppression",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const advertsDealerAccountColumn = [
  {
    label: "DATE POSTED",
    name: "date",
  },
  {
    label: "IMPRESSION",
    name: "imppression",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const transactionDealerAccountColumn = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "DATE",
    name: "date",
  },
  {
    label: "ITEM",
    name: "item",
  },
  {
    label: "AMOUNT",
    name: "amount",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const transactionPrivateAccountColumn = [
  {
    label: "ID",
    name: "id",
  },
  {
    label: "DATE",
    name: "date",
  },
  {
    label: "ITEM",
    name: "item",
  },
  {
    label: "AMOUNT",
    name: "amount",
  },
  {
    label: "STATUS",
    name: "status",
  },
];

export const bannerImagesColumn = [
  {
    label: "PAGE NAME",
    name: "page_name",
  },
  {
    label: "LAST UPDATED",
    name: "last_updated",
  },
  {
    label: "CURRENT BANNER",
    name: "current_banner",
    type: "image",
    style: "w-100",
  },
];

export const locationColumn = [
  {
    label: "Id",
    name: "id",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Latitude",
    name: "latitude",
  },
  {
    label: "Longitude",
    name: "longitude",
  },
  {
    label: "Ip Address",
    name: "ip_address",
  },
  {
    label: "Device Type",
    name: "device_type",
  },
];

export const rechargeColumn = [
  {
    label: "Id",
    name: "id",
  },
  {
    label: "UID",
    name: "uid",
  },
  {
    label: "Amount",
    name: "amount",
  },
  {
    label: "Status",
    name: "status",
  },
  {
    label: "Created At",
    name: "created_at",
  },
];

export const logsColumn = [
  { label: "Uid", name: "uid" },
  { label: "Platform", name: "platform" },
  { label: "Ip Address", name: "ip_address" },
  { label: "Type", name: "type" },
  { label: "Device Id", name: "device_id" },
  { label: "Device Token", name: "device_token" },
  { label: "App Version", name: "app_version" },
  { label: "Date", name: "date" },
];

export const branchColumn = [
  { label: "Id", name: "id" },
  { label: "Uid", name: "uid" },
  { label: "Parent Id", name: "parent_id" },
  { label: "Owner Name", name: "owner_name" },
  { label: "Company Name", name: "company_name" },
  { label: "Gst Number", name: "gst_number" },
  { label: "Permanent Address", name: "permanent_address" },
  { label: "Latitude", name: "latitude" },
  { label: "Longitude", name: "longitude" },
  { label: "Aadhar Number", name: "addhaar_number" },
  { label: "Pan Number", name: "pan_number" },
  { label: "Linkdin", name: "linkdin" },
  { label: "Facebook", name: "facebook" },
  { label: "Instagram", name: "instagram" },
  { label: "Type", name: "type" },
  { label: "Call Charges", name: "call_charges" },
  { label: "Chat Charges", name: "chat_charges" },
  { label: "Franchise", name: "franchise" },
  { label: "Top Rated", name: "top_rated" },
];

export const searchColumn = [
  { label: "Id", name: "id" },
  { label: "Uid", name: "uid" },
  { label: "Keywords", name: "keyword" },
  { label: "Count", name: "count" },
  { label: "Search Date", name: "created_at" },
];

export const staffColumn = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Branch Id",
    name: "branch_id",
  },
  {
    label: "Created At",
    name: "created_at",
  },
];

export const specializationColumn = [
  {
    label: "Specializations",
    name: "name",
  },
];

export const pending_requestColumn = [
  {
    label: "Id",
    name: "id",
  },
  {
    label: "Branch id",
    name: "branch_id",
  },
  {
    label: "Owner name",
    name: "owner_name",
  },
  {
    label: "Company name",
    name: "company_name",
  },
  {
    label: "Address",
    name: "permanent_address",
  },
  {
    label: "type",
    name: "type",
  },
];

export const selectionColumn = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Type",
    name: "type",
  },
  {
    label: "Status",
    name: "status",
  },
];

export const serviceLocationColumn = [
  {
    label: "City",
    name: "city",
  },
  {
    label: "State",
    name: "state",
  },
  {
    label: "Status",
    name: "status",
  },
];

export const blogsCategoryColumn = [];

export const blogsColumn = [
  {
    label: "Title",
    name: "title",
  },
  {
    label: "Image",
    name: "image",
    type: "image",
  },
  {
    label: "Views",
    name: "view_count",
  },
  {
    label: "Author Name",
    name: "author_name",
  },
  {
    label: "Author Image",
    name: "author_image",
    type: "image",
    style: "w-50",
  },
];

export const categoryColumn = [
  {
    label: "Name",
    name: "name",
  },
];

export const subCategoryColumn = [
  {
    label: "Name",
    name: "name",
  },
];

export const editorModules = {
  toolbar: [
    [{ header: [1, 2, , 3, 4, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false, // Disable visual matching
  },
};

export const editorFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const helpCategoryOptions = [
  { label: "User", value: "user" },
  { label: "Business", value: "business" },
];

export const helpCatCol = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Type",
    name: "type",
  },
  {
    label: "Slug",
    name: "slug",
  },
];

export const helpStatus = ["enable", "disable"];

export const helpColumn = [
  { label: "Question", name: "question" },
  { label: "Type", name: "type" },
  { label: "Status", name: "status" },
  { label: "Youtube URL", name: "youtube_ref" },
];

export const expertsColumn = [
  { label: "Name", name: "name" },
  { label: "Phone", name: "phone" },
  { label: "Online", name: "online" },
  { label: "Address", name: "current_address" },
];
