import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { editPageApiFun } from '../../services/api';

export const editPageFun = createAsyncThunk('/editpages', async (formData, { rejectWithValue }) => {
  try {
    const response = await editPageApiFun(formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const editPageSlice = createSlice({
  name: 'editPage',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    editPage:[],
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(editPageFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editPageFun.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.editPage=action.payload;
      })
      .addCase(editPageFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default editPageSlice.reducer;

