import metricsFunApi from "./metrics.json";
import keyStatsFunApi from "./keyStats.json";
import overviewFunApi from "./overview.json";
import pagesFunApi from "./pages.json";
import blockWordsFunApi from "./block_words.json";
import singleUserPendingApprovalApi from "./singleUserDetails.json";
import editPageApi from "./editpage.json";
import pendingApprovalActionApi from "./actionPendingAprroval.json";
import pendingAprrovalDeleteApi from "./deletePendingAprroval.json";
import deletePageAPi from "./deletePages.json";
import userAccountListApi from "./user_accounts.json";
import reportApi from "./report.json";
import pricingApi from "./pricing.json";
import editSubscriptionApi from "./editSubscription.json";
import addSubscriptionApi from "./addSubscription.json";
import accountDetailsApi from "./accountDetails.json";
import trasncationDeleteApi from "./transcationDelete.json";
import getUsersApi from "./sendNotificationUser.json";
import getBannerImageApi from "./bannerImages.json";
import graphApi from "./graph.json";
import myprofile from "./myprofile.json";
import axios from "axios";

const baseUrl = "https://apiofficeback.chownk.in/admin";

const token = localStorage.getItem("token");
console.log(token, "TOKEN");

export const getProfiledata = (data) => {
  const response = myprofile;
  return response;
};

export const getUserApi = async () => {
  const token = localStorage.getItem("token");
  try {
    let responce = await axios.get(`${baseUrl}/v1/users_list`, {
      headers: { "x-access-token": token },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchPartnerUser = async () => {
  const token = localStorage.getItem("token");
  try {
    let responce = await axios.get(`${baseUrl}/v1/partner_list`, {
      headers: { "x-access-token": token },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const changeStatus = async (data) => {
  const token = localStorage.getItem("token");
  try {
    let responce = await axios.post(`${baseUrl}/v1/change_status`, data, {
      headers: { "x-access-token": token },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const addExpertFun = async (data) => {
  const token = localStorage.getItem("token");
  try {
    let response = await axios.post(`${baseUrl}/v1/expert`, data, {
      headers: { "x-access-token": token },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getExpertFun = async (data) => {
  const token = localStorage.getItem("token");
  try {
    let response = await axios.get(`${baseUrl}/v1/expert`, {
      headers: { "x-access-token": token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPartnerProfile = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/branch_detail`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchLogs = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/fetch_logs`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchChildBranches = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/fetch_child_branches`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchLocation = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/fetch_location`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchRecharges = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/fetch_recharge`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchSearchLogs = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/fetch_recent_search`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const fetchStaff = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/fetch_staff`,
      { id },
      { headers: { "x-access-token": token } }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const editBranch = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.put(
      `${baseUrl}/v1/edit_branch/${id}`,
      formData,
      {
        headers: {
          "x-access-token": token,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const addBranch = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(`${baseUrl}/v1/add_branch`, formData, {
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data",
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getSubscriptions = async () => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.get(`${baseUrl}/v1/plan`, {
      headers: { "x-access-token": token },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const addSubscriptionPlan = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(`${baseUrl}/v1/plan`, formData, {
      headers: { "x-access-token": token },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const editSubscriptionPlan = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(`${baseUrl}/v1/plan/${id}`, formData, {
      headers: { "x-access-token": token },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const addSpecialization = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(
      `${baseUrl}/v1/specialization`,
      formData,
      {
        headers: { "x-access-token": token },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getSpecialization = async () => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.get(`${baseUrl}/v1/specialization`, {
      headers: { "x-access-token": token },
    });
    const responceData = responce.data;
    return responceData;
  } catch (e) {
    throw e;
  }
};

export const editSpecialization = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(
      `${baseUrl}/v1/specialization/${id}`,
      formData,
      {
        headers: { "x-access-token": token },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getPendingRequestFun = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseUrl}/v1/fetchUpdatesRequest`, {
      headers: { "x-access-token": token },
    });
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const updatePendingRequestFun = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(
      `${baseUrl}/v1/update_request/${id}`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getSelectionDocFun = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseUrl}/v1/selection_documents`, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const addSelectionDocFun = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(
      `${baseUrl}/v1/selection_documents`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const editSelectionDocFun = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(
      `${baseUrl}/v1/selection_documents/${id}`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const branchClaimList = async () => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.get(`${baseUrl}/v1/fetch_branches_claim`, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const updateBranchPrimaryDetails = async (id, formData) => {
  const token = localStorage.getItem("token");
  console.log(id, formData);
  try {
    const responce = await axios.put(
      `${baseUrl}/v1/update_primery_profile/${id}`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const singleUserDetails = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/user_detail`,
      { id },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const findCategoryAvail = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(`${baseUrl}/v1/findCategorie`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addServiceLocationFun = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(`${baseUrl}/v1/location`, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getServiceLocationFun = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseUrl}/v1/location`, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const editLocationFun = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(`${baseUrl}/v1/location/${id}`, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    const responceData = responce.data;
    return responceData;
  } catch (e) {
    throw e;
  }
};

export const addBlogFun = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(`${baseUrl}/v1/blogs`, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getAllBlogsFun = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseUrl}/v1/blogs`, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const editBlogFun = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(`${baseUrl}/v1/blogs/${id}`, formData, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const addCategory = async (formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(
      `${baseUrl}/v1/blog_categories`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responceData = responce.data;
    return responceData;
  } catch (e) {
    throw e;
  }
};

export const getAllCategoriesFun = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/blog_categories_main`,
      { id },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = data.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const editCategoriesFun = async (id, formData) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(
      `${baseUrl}/v1/blog_categories/${id}`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const getSingleBlogApi = async (slug) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/SingleBlog`,
      {
        slug,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responceData = data.data;
    return responceData;
  } catch (e) {
    throw e;
  }
};

export const findSlug = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/findBlogSlug`,
      {
        slug: body.slug,
        id: body.id,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteBlogFun = async (body) => {
  const token = localStorage.getItem("token");
  try {
    console.log("DLT", body);
    const responce = await axios.delete(`${baseUrl}/v1/blogs`, {
      headers: {
        "x-access-token": token,
      },
      data: body,
    });
    const responceData = responce.data;
    return responceData;
  } catch (error) {
    throw error;
  }
};

export const addHelpCategoryApi = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(`${baseUrl}/v1/help_categories`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getHelpCategoryApi = async () => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.get(`${baseUrl}/v1/help_categories`, {
      headers: {
        "x-access-token": token,
      },
    });
    const response = data.data;
    return response;
  } catch (error) {
    throw error;
  }
};

export const editHelpCategoryApi = async (id, body) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.put(
      `${baseUrl}/v1/help_categories/${id}`,
      body,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const searchHelpCategory = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(
      `${baseUrl}/v1/help_categories_search`,
      body,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getHelpCatByTypeApi = async (type) => {
  const token = localStorage.getItem("token");
  try {
    const { data } = await axios.post(
      `${baseUrl}/v1/HelpCategoryByType`,
      { type },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = data.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getHelpApi = async (type) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(
      `${baseUrl}/v1/GetHelp`,
      { type },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const editHelpApi = async (id, body) => {
  const token = localStorage.getItem("token");
  try {
    console.log(body, "BODY");
    const responce = await axios.put(`${baseUrl}/v1/EditHelp/${id}`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const addHelpApi = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(`${baseUrl}/v1/AddHelp`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const getsingleTestApi = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const responce = await axios.post(
      `${baseUrl}/v1/SingleHelp`,
      { id },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const responseData = responce.data;
    return responseData;
  } catch (error) {
    throw error;
  }
};
export const getMetricsApi = async (time) => {
  try {
    const response = metricsFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getKeyStatsApi = async (time) => {
  try {
    const response = keyStatsFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getOverviewApi = async (time) => {
  try {
    const response = overviewFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getPagesApi = async () => {
  try {
    const response = pagesFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getBlockWordApi = async (time) => {
  try {
    const response = blockWordsFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getSingleUserPendingApprovalFun = async (body) => {
  try {
    const response = singleUserPendingApprovalApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const editPageApiFun = async (body) => {
  try {
    const response = editPageApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const pendingApprovalActionFun = async (body) => {
  try {
    const response = await pendingApprovalActionApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};
export const deletePendingAprrovalFun = async (body) => {
  try {
    const response = await pendingAprrovalDeleteApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const deletePagesFun = async (body) => {
  try {
    const response = await deletePageAPi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const userAccountListFun = async (body) => {
  try {
    const response = await userAccountListApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const reportFun = async (body) => {
  try {
    const response = await reportApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getPricingFun = async () => {
  try {
    const response = await pricingApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const editSubscriptionFun = async (body) => {
  try {
    const response = await editSubscriptionApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const addSubscriptionFun = async (body) => {
  try {
    const response = await addSubscriptionApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getAccountDetailsFun = async (body) => {
  try {
    const response = await accountDetailsApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const trasncationDeleteFun = async (body) => {
  try {
    const response = await trasncationDeleteApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getUsersFun = async () => {
  try {
    const response = getUsersApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getBannerImageFun = async () => {
  try {
    const response = getBannerImageApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};
export const graphFun = async (time) => {
  try {
    const response = graphApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const submitLoginForm = async (formData) => {
  try {
    let responce = await axios.post(`${baseUrl}/v1/login`, formData);
    const responseData = responce.data;
    console.log(responseData, "Data");
    return responseData;
  } catch (e) {
    throw e;
  }
};
export const delete_help = async (body) => {
  try {
    let responce = await axios.delete(`${baseUrl}/v1/delete_help`, {
      headers: { "x-access-token": token },
      data: body,
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const deleteCategoryHelpApi = async (body) => {
  try {
    let responce = await axios.delete(`${baseUrl}/v1/help_categories`, {
      headers: { "x-access-token": token },
      data: body,
    });
    const responseData = responce.data;
    return responseData;
  } catch (e) {
    throw e;
  }
};
