import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import Button from "../../Tools/Button";
import { editSpecializationFun } from "../../../Reducer/Specialization/specializationSlice";

const EditSpecialization = ({ onHide, id, name }) => {
  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(editSpecializationFun({ id, data }));
    onHide();
  };

  useEffect(() => {
    setValue("name", name);
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroupLabelInput
          label={"Specialization name"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"name"}
          placeholder={"Specialization name"}
        />
        <div className="modal-footer">
          <Button
            style={"btn btn-secondary"}
            title={"Close"}
            type={"button"}
            onClick={() => onHide()}
          ></Button>
          <Button
            style={"btn btn-primary"}
            type={"submit"}
            title={"Edit"}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default EditSpecialization;
