import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function DynamicChart({categories, data}) {
    console.log("categories1", categories);
    console.log("data1", data)
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Desktops',
        data: data,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories,
      },
    },
  });
console.log("chartData". chartData)
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
}

export default DynamicChart;
