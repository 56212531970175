import React from 'react'
import { useNavigate } from 'react-router'
export default function MyprofileDetails({data}) {
   const navigate=useNavigate();
   const handelclick=()=>{
     navigate('/EditProfile', {state: { data: data}});
   }
  return (
    <div className='overview'>
       <h3 class="pt-0">Basic details
             <a class="edt" onClick={handelclick}><img src="../assets/images/pencil.png" alt="pencil"/>Edit profile</a>
             </h3>
      <div class="my_profile">
            
            <div class="row">
               <div class="col-sm-3 col-xl-2">
                  <div class="imgs">
                     <img class="w-100" src={data.profile}align="img"/>
                  </div>
               </div>
               <div class="col-sm-9 col-xl-10">
                  <div class="row">
                     <div class="col-md-4 ">
                        <h6>Name</h6>
                        <p>{data.first_name} {data.last_name}</p>
                     </div>
                     <div class="col-md-4">
                        <h6>Email</h6>
                        <p>{data.email} </p>
                     </div>
                     <div class="col-md-4">
                        <h6>Phone</h6>
                        <p>+{data.phone_number}</p>
                     </div>
                    
                  </div>
               </div>
            </div>
           
  
          
         </div>
    </div>
  )
}
