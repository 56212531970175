import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addHelpApi,
  addHelpCategoryApi,
  editHelpApi,
  editHelpCategoryApi,
  getHelpApi,
  getHelpCatByTypeApi,
  getHelpCategoryApi,
  getsingleTestApi,
} from "../../services/api";

export const addHelpCategory = createAsyncThunk(
  "/addhelpCat",
  async (body, { rejectWithValue }) => {
    try {
      const response = await addHelpCategoryApi(body);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getHelpCategory = createAsyncThunk(
  "gethelpCat",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getHelpCategoryApi();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editHelpCategory = createAsyncThunk(
  "edithelpCat",
  async (body, { rejectWithValue }) => {
    try {
      const response = await editHelpCategoryApi(body.id, body.data);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getHelpCatbyType = createAsyncThunk(
  "getHelpCatbyType",
  async (type, { rejectWithValue }) => {
    try {
      const response = await getHelpCatByTypeApi(type);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addUserHelp = createAsyncThunk(
  "addUserHelp",
  async (body, { rejectWithValue }) => {
    try {
      const response = await addHelpApi(body);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getUserHelp = createAsyncThunk(
  "getHelp",
  async (type, { rejectWithValue }) => {
    try {
      const response = await getHelpApi(type);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getBusinessHelp = createAsyncThunk(
  "getBusinessHelp",
  async (type, { rejectWithValue }) => {
    try {
      const response = await getHelpApi(type);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editHelp = createAsyncThunk(
  "editHelp",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data.body, "REDUCER");
      const response = await editHelpApi(data.id, data.body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSingleHelp = createAsyncThunk(
  "getSingleHelp",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getsingleTestApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const helpSlice = createSlice({
  name: "help",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    userHelp: [],
    businessHelp: [],
    helpCategories: [],
    helpCatByType: [],
    singleHelp: {},
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      .addCase(addHelpCategory.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addHelpCategory.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
      })
      .addCase(addHelpCategory.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getHelpCategory.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getHelpCategory.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.helpCategories = action.payload;
      })
      .addCase(getHelpCategory.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(editHelpCategory.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editHelpCategory.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
      })
      .addCase(editHelpCategory.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getHelpCatbyType.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getHelpCatbyType.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.helpCatByType = action.payload;
      })
      .addCase(getHelpCatbyType.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(addUserHelp.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addUserHelp.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
      })
      .addCase(addUserHelp.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getUserHelp.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getUserHelp.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.userHelp = action.payload;
      })
      .addCase(getUserHelp.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getBusinessHelp.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getBusinessHelp.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.businessHelp = action.payload;
      })
      .addCase(getBusinessHelp.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(editHelp.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editHelp.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.businessHelp = action.payload;
      })
      .addCase(editHelp.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getSingleHelp.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSingleHelp.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.singleHelp = action.payload;
      })
      .addCase(getSingleHelp.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default helpSlice.reducer;
