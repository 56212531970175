import React, { useState } from "react";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addCategories } from "../../../Reducer/category/categorySlice";
import axios from "axios";
import { findCategoryAvail } from "../../../services/api";

const AddCategory = ({ onHide }) => {
  const [categoryStatus, setCategoryStatus] = useState([]);

  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const body = { ...data, parent_id: 0 };
    dispatch(addCategories(body));
    onHide();
  };

  const handleChange = async (e) => {
    try {
      const body = { id: 0, name: e.target.value };
      const data = await findCategoryAvail(body);
      setCategoryStatus(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class={"form-group"}>
          <label>{"Category Name"}</label>
          <input
            class={"form-control"}
            name="name"
            {...register("name")}
            onChange={handleChange}
          />
        </div>
        <p>{categoryStatus.message}</p>
        <div className="modal-footer"></div>
        <Button type="submit" disabled={!categoryStatus.status}>
          Add
        </Button>
      </form>
    </div>
  );
};

export default AddCategory;
