import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import FormGroupOption from "../../Tools/FormGroupOption";
import { editSubscriptonAnalytics } from "../../../data/data";
import { useDispatch, useSelector } from "react-redux";
import { addSelectionDoc } from "../../../Reducer/selection/selectionSlice";

const AddSelection = ({ show, onHide, data }) => {
  const { register, handleSubmit } = useForm();
  const status_options = ["enable", "disable"];
  const type_options = ["claim"];

  const { error, success, submitting, all_selections } = useSelector(
    (state) => state.selection
  );
  const dispatch = useDispatch();

  const handleClick = (data) => {
    dispatch(addSelectionDoc(data));
    onHide();
  };
  return (
    <div>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Document Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroupLabelInput
            label={"Name"}
            name={"name"}
            event={register}
            placeholder={"Name"}
            parentStyle={"form-group"}
            childStyle={"form-control"}
          />
          <FormGroupOption
            label={"Status"}
            event={register}
            options={status_options}
            name={"status"}
            parentStyle={"col-sm-12 mb-3"}
            childStyle={"form-group"}
            selectStyle={"form-control"}
          />
          <FormGroupOption
            label={"Type"}
            event={register}
            options={type_options}
            name={"type"}
            parentStyle={"col-sm-12 mb-3"}
            childStyle={"form-group"}
            selectStyle={"form-control"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit(handleClick)}>
            Add Selection
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddSelection;
