import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPricingFun } from '../../services/api';

export const pricingFun = createAsyncThunk('/pricing', async () => {
    try {
      const response = await getPricingFun();
      return response.data;
    } catch (error) {
      console.log("error", error)
    }
  });

const pricingSlice = createSlice({
  name: 'pricing',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    trade_users:[],
    indiviual_users:[]
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(pricingFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(pricingFun.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.trade_users=action.payload.trade_users;
        state.indiviual_users=action.payload.indiviual_users;
      })
      .addCase(pricingFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default pricingSlice.reducer;

