import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBlog, fetchAllBlogs } from "../../../Reducer/blogs/blogSlice";
import { deleteBlogApi } from "../../../services/api";

const DeleteBlog = ({ data, onHide }) => {
  const dispatch = useDispatch();
  const handleClick = async () => {
    const body = {
      id: data.id,
      image: data.image,
      author_image: data.author_image,
    };
    dispatch(deleteBlog(body));
    onHide();
  };
  return (
    <div>
      <Button onClick={handleClick}>Yes</Button>
      <Button onClick={() => onHide()}> No</Button>
    </div>
  );
};

export default DeleteBlog;
