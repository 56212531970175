import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addBlogFun,
  deleteBlogApi,
  deleteBlogFun,
  editBlogFun,
  getAllBlogsFun,
  getSingleBlogApi,
} from "../../services/api";

export const addNewBlog = createAsyncThunk(
  "/addblogs",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addBlogFun(formData);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const fetchAllBlogs = createAsyncThunk(
  "/getblogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllBlogsFun();
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getSingleBlog = createAsyncThunk(
  "/getSingleBlog",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await getSingleBlogApi(slug);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const editBlog = createAsyncThunk(
  "/editblog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editBlogFun(data.id, data.formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "/deleteblog",
  async (body, { rejectWithValue }) => {
    try {
      console.log(typeof body, body);
      const response = await deleteBlogFun(body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const blogSlice = createSlice({
  name: "blog",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    all_blogs: [],
    singleBlog: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewBlog.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addNewBlog.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(addNewBlog.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(fetchAllBlogs.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchAllBlogs.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.all_blogs = action.payload;
        state.success = true;
      })
      .addCase(fetchAllBlogs.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getSingleBlog.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
        state.singleBlog = {};
      })
      .addCase(getSingleBlog.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.singleBlog = action.payload;
      })
      .addCase(getSingleBlog.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(editBlog.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editBlog.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(editBlog.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(deleteBlog.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(deleteBlog.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export default blogSlice.reducer;
