import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BoostrapModel from "../../Tools/BoostrapModel";
import AddHelp from "./AddHelp";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTable from "../../Tools/Table";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessHelp, getUserHelp } from "../../../Reducer/Help/helpSlice";
import { helpColumn } from "../../../data/data";
import { delete_help } from "../../../services/api";
import swal from "sweetalert";

const Help = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { success, submitting, error, businessHelp, userHelp } = useSelector(
    (state) => state.help
  );
  const { pathname } = useLocation();

  useEffect(() => {
    if (type == "user") {
      dispatch(getUserHelp(type));
    } else {
      dispatch(getBusinessHelp(type));
    }
  }, [pathname]);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        navigate(`/${row.type}-help/${row.id}`);
      },
      inlineStyle: {
        padding: "5px",
      },
      type: "text",
    },
    {
      label: "Delete",
      style: "Reject",
      onclick: (row) => {
        console.log("row-->", row);
        const body = {
          id: row.id,
        };
        delete_help(body)
          .then((response) => {
            if (response.status) {
              swal(response.message);
              if (type == "user") {
                dispatch(getUserHelp(type));
              } else {
                dispatch(getBusinessHelp(type));
              }
            }
          })
          .catch((e) => console.log("error", e));

        if (type == "user") {
          dispatch(getUserHelp(type));
        } else {
          dispatch(getBusinessHelp(type));
        }
      },
      inlineStyle: {
        padding: "5px",
      },
      type: "text",
    },
  ];

  return (
    <div>
      <h3>
        Help
        <Button
          onClick={() => {
            if (type == "user") {
              navigate("/user-help/addhelp");
            } else {
              navigate("/business-help/addhelp");
            }
          }}
        >
          Add Help
        </Button>
      </h3>
      <BasicTable
        rows={
          type == "user"
            ? userHelp
              ? userHelp
              : []
            : businessHelp
            ? businessHelp
            : []
        }
        columns={helpColumn}
        actions={actions}
      />
    </div>
  );
};

export default Help;
