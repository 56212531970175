import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BasicTable from "../../Tools/Table";
import { blockWordsColumn, deleteOption, issueOption, refundOption } from "../../../data/data";
import swal from "sweetalert";

import TopbarWithDateandSearch from '../../Tools/TopbarWithDateandSearch';
import { BlockWordFun } from "../../../Reducer/blockWords/blockWordsSlice"
import AddBlockWords from "./AddBlockWords";
// import { deleteOption, issueOption, refundOption } from "../../../data/data";
import ReasonModel from "../../Tools/ReasonModel";
import BoostrapModel from "../../Tools/BoostrapModel";
import { reportFun } from "../../../services/api";
document.title="Block Words";
const BlockWords = () => {  
    const rows = useSelector(state => state.blockWords?.blockWords)
    const dispatch = useDispatch();
    React.useEffect(()=>{
      dispatch(BlockWordFun({time: "This weeks"}))
    }, [dispatch])
    const data = rows && rows.map((item) => {
        return {
            ...item,
            words : item.words.English,
        }
    });
    const searchBar = (data)  => {
        console.log("data1", data)
    }
    const [show, setShow] = React.useState(false);
    const [state, setState] = React.useState({
        id: '',
        label: '',
        heading: "",
        buttonTitle: '',
        option: [],
        type: ''
    })
    const handleSubmit = async (data) => {
        const body = {
            data
        }
        try {
            const response = await reportFun(body);
            if (response.status) {
                setShow(!show);
                swal('success', 'sucessss', 'success');
            }
        }
        catch (e) {
            console.log("error", e);
        }
    }
    

    const actions = [
      {
          label: "Edit",
          onclick: (id) => {
              setState({
                  id: id,
                  title: "Edit",
                  heading: "Edit",
                  buttonTitle: "Edit",
                  option: issueOption,
                  type: "note"
              })
              setShow(!show)
          }
      },
      {
          label: "Delete",
          onclick: (id) => {
              console.log("id", id)
              setState({
                  id: id,
                  heading: "Delete",
                  title: "Delete",
                  buttonTitle: "Delete",
                  option: deleteOption,
                  type: ""
              })
              setShow(!show)
          }

      },
  

  ] 
    const onsubmit = (data) => {
        let formData = new FormData();
        formData.append('time', data);
      dispatch(BlockWordFun(formData))
    }
    
   return(<div class="overview block_words mb-0">
        <AddBlockWords />
       <TopbarWithDateandSearch style={"trade"} heading={"Block Words"} searchOnClick={searchBar} dateOnclick={onsubmit}  page={'no'}/>
         <BasicTable actionType={"dropdown"} actions={actions}  columns={blockWordsColumn} rows={data ? data : []}  />
         <BoostrapModel show={show} heading={state.heading} onHide={() => setShow(!show)} component={<ReasonModel
            id={state.id}
            label={state.title
            }
            meta={'editpage'} 
            buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)} />} />
   </div>)
}
export default BlockWords;