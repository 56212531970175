import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccountDetailsFun } from '../../services/api';

export const getAccountDetails = createAsyncThunk('/account_details', async (formData, { rejectWithValue }) => {
    try {
      const response = await getAccountDetailsFun(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

const accountDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    privateAccount:[],
    dealerAccount:[]
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getAccountDetails.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAccountDetails.fulfilled, (state,action) => {
        console.log("actionPayload", action.payload)
        state.submitting = false;
        state.success=true;
        state.privateAccount=action.payload.private_account;
        state.dealerAccount=action.payload.dealer_account;
      })
      .addCase(getAccountDetails.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default accountDetailsSlice.reducer;

