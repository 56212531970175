import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDocSelection } from "../../../Reducer/selection/selectionSlice";
import { Button } from "react-bootstrap";
import AddSelection from "./AddSelection";
import BasicTable from "../../Tools/Table";
import { selectionColumn } from "../../../data/data";

import EditSelection from "./EditSelection";
import swal from "sweetalert";
import BoostrapModel from "../../Tools/BoostrapModel";

const Selection = () => {
  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const { success, submitting, error, all_selections } = useSelector(
    (state) => state.selection
  );

  useEffect(() => {
    dispatch(fetchAllDocSelection());
  }, [dispatch]);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        setEditData(row);
        setEditShow(!editShow);
      },
      type: "text",
    },
  ];

  if (error && error.status) {
    switch (error.status) {
      case 403:
        swal("error", error.data.message, "error");
        break;
      default:
        swal("error", "Something went wrong", "error");
        break;
    }
  }

  const onHide = () => setAddShow(!addShow);
  const editOnHide = () => setEditShow(!editShow);

  return (
    <div className="dashboard-pricing selected-document">
      <h3>
        {"Document Selection"}
        <Button onClick={() => setAddShow(true)} className="freeze_btn">
          {"Add Selection"}
        </Button>
      </h3>
      <BasicTable
        rows={all_selections ? all_selections : []}
        columns={selectionColumn}
        actions={actions}
      />
      <AddSelection show={addShow} onHide={onHide} />
      <BoostrapModel
        show={editShow}
        heading={"Edit Selection"}
        component={
          <EditSelection onHide={editOnHide} selectedData={editData} />
        }
        onHide={() => setEditShow(!editShow)}
      />
    </div>
  );
};

export default Selection;
