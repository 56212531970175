import NotificationFilter from "./filter";
import NotificationMessage from "./notificationMessage";

const SendNotification = () => {
    return (<div class="overview Notifications mb-0">

        <h2>Send notifications </h2>
        <div class="send_noti">
            <NotificationFilter />
            <NotificationMessage heading={"Enter your notification title"} />
        </div></div>)
}
export default SendNotification;