import React, { useEffect, useState } from "react";
import BoostrapModel from "../../Tools/BoostrapModel";
import { Button } from "react-bootstrap";
import AddHelpCategory from "./AddHelpCategory";
import { useDispatch, useSelector } from "react-redux";
import BasicTable from "../../Tools/Table";
import { categoryColumn, helpCatCol } from "../../../data/data";
import { getHelpCategory } from "../../../Reducer/Help/helpSlice";
import EditHelpCategory from "./EditHelpCategory";
import { deleteCategoryHelpApi } from "../../../services/api";
import swal from "sweetalert";

const HelpCategory = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editData, setEditData] = useState({});

  const dispatch = useDispatch();
  const { helpCategories, success, submitting, error } = useSelector(
    (state) => state.help
  );

  useEffect(() => {
    dispatch(getHelpCategory());
  }, []);
  // const [editShow, setEditShow] = useState(false);
  // const [parent_id, setParent_id] = useState("");
  // const [editData, setEditData] = useState({});

  // const dispatch = useDispatch();
  // const { success, submitting, error, all_categories } = useSelector(
  //   (state) => state.category
  // );

  // useEffect(() => {
  //   dispatch(getCategories(0));
  // }, []);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        setEditData(row);
        setEditShow(!editShow);
      },
      type: "text",
    },
    {
      label: "Delete",
      style: "Reject",
      onclick: (row) => {
        const body = {
          id: row.id,
        };
        deleteCategoryHelpApi(body)
          .then((response) => {
            if (response.status) {
              swal(response.message);
              // setTimeout(() => {
              dispatch(getHelpCategory());
              // }, 2000);
            }
          })
          .catch((e) => {
            swal(e.response.data.message);
            console.log("delete help category--->", e);
          });
      },
      inlineStyle: {
        padding: "5px",
      },
      type: "text",
    },
  ];

  const onHide = () => {
    setShow(false);
    setEditShow(false);
  };
  return (
    <div>
      <h3>
        Help Category
        <Button onClick={() => setShow(!show)}>Add Category</Button>
      </h3>
      <BasicTable
        rows={helpCategories ? helpCategories : []}
        columns={helpCatCol}
        actions={actions}
      />
      <BoostrapModel
        show={show}
        onHide={onHide}
        heading={"Add Category"}
        component={<AddHelpCategory onHide={onHide} />}
      />

      <BoostrapModel
        show={editShow}
        onHide={onHide}
        heading={"Edit Category"}
        component={<EditHelpCategory onHide={onHide} editData={editData} />}
      />
    </div>
  );
};

export default HelpCategory;
