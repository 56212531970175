import React, { useEffect, useState } from "react";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import ReactQuill from "react-quill";
import FormGroupOption from "../../Tools/FormGroupOption";
import { Button } from "react-bootstrap";
import {
  editorFormats,
  editorModules,
  helpCategoryOptions,
  helpStatus,
} from "../../../data/data";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleBlog } from "../../../Reducer/blogs/blogSlice";
import {
  editHelp,
  getHelpCatbyType,
  getSingleHelp,
} from "../../../Reducer/Help/helpSlice";

const EditHelp = ({ type }) => {
  const [answers, setAnswers] = useState("");
  const { register, handleSubmit, setValue } = useForm();
  const { singleHelp, helpCatByType } = useSelector((state) => state.help);
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getHelpCatbyType(type));
    dispatch(getSingleHelp(id));
  }, []);

  useEffect(() => {
    setAnswers(singleHelp.answers);
    setValue("question", singleHelp.question);
    setValue("cat_id", singleHelp.cat_id);
    setValue("status", singleHelp.status);
    setValue("youtube_ref", singleHelp.youtube_ref);
  }, [singleHelp]);

  const handleAnswer = (content) => {
    setAnswers(content);
  };

  const onSubmit = (data) => {
    try {
      const body = { ...data, answers, type };
      dispatch(editHelp({ id, body }));
      navigate(`/${type}-help`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroupLabelInput
          label={"Question"}
          name="question"
          event={register}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          placeholder={"Question"}
        />
        <div className="my-3">
          <label>Answer</label>
          <ReactQuill
            formats={editorFormats}
            modules={editorModules}
            onChange={handleAnswer}
            value={answers}
          />
        </div>
        <div class={"col-sm-12 mb-3"}>
          <div class={"form-group"}>
            <label>Category</label>
            <select
              class={"form-control"}
              name="cat_id"
              {...register("cat_id", { required: "Category is required" })}
            >
              {helpCatByType.length > 0 &&
                helpCatByType.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
            </select>
          </div>
        </div>
        <FormGroupOption
          label={"Status"}
          parentStyle={"col-sm-12 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          options={helpStatus}
          event={register}
          name={"status"}
        />
          {/* <FormGroupLabelInput
            label={"Youtube Url"}
            name="youtube_ref"
            event={register}
            parentStyle={"form-group"}
            childStyle={"form-control"}
            placeholder={"Yotube Url"}
          /> */}
        <div class={"form-group"}>
          <label>{"Youtube Url"}</label>
          <input
            class={"form-control"}
            name="youtube_ref"
            {...register("youtube_ref")}
            placeholder={"Youtube Url"}
          />
        </div>
        <Button className="my-3" type="submit">
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export default EditHelp;
