import React, { useState } from "react";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addCategories } from "../../../Reducer/category/categorySlice";
import axios from "axios";
import { findCategoryAvail, searchHelpCategory } from "../../../services/api";
import FormGroupOption from "../../Tools/FormGroupOption";
import { helpCategoryOptions } from "../../../data/data";
import { addHelpCategory } from "../../../Reducer/Help/helpSlice";

const AddHelpCategory = ({ onHide }) => {
  const [categoryStatus, setCategoryStatus] = useState({});

  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formData = new FormData();
    try {
      formData.append("name", data.name);
      formData.append("type", data.type);
      formData.append("image", data.image[0]);
      dispatch(addHelpCategory(formData));
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (e) => {
    try {
      const body = { name: e.target.value };
      const data = await searchHelpCategory(body);
      setCategoryStatus(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class={"form-group"}>
          <label>{"Category Image"}</label>
          <input
            class={"form-control"}
            type="file"
            name="image"
            {...register("image", { required: "Image is required" })}
          />
        </div>
        <div class={"form-group"}>
          <label>{"Category Name"}</label>
          <input
            class={"form-control"}
            name="name"
            {...register("name", { required: "Name is required" })}
            onChange={handleChange}
          />
        </div>

        <p>{categoryStatus.message}</p>

        <div class={"col-sm-6 mb-3"}>
          <div class={"form-group"}>
            <label>Type</label>
            <select
              class={"form-control"}
              name="type"
              {...register("type", { required: "Type is required" })}
            >
              {helpCategoryOptions.length > 0 &&
                helpCategoryOptions.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="modal-footer"></div>
        <Button type="submit">Add</Button>
      </form>
    </div>
  );
};

export default AddHelpCategory;
