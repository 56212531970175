import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMetricsApi, getKeyStatsApi, getOverviewApi, graphFun } from '../../services/api';

export const metricsFun = createAsyncThunk('/metric', async (formData, { rejectWithValue }) => {
  try {
    console.log("formData", formData);
    const response = await getMetricsApi(formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const keyStatsFun = createAsyncThunk('/keystats', async (formData, { rejectWithValue }) => {
  try {
    console.log("formData", formData);
    const response = await getKeyStatsApi(formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const overviewFun = createAsyncThunk('/overview', async (formData, { rejectWithValue }) => {
  try {
    console.log("formData", formData);
    const response = await getOverviewApi(formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});
export const getGraphData = createAsyncThunk('/graph', async (formData, { rejectWithValue }) => {
  try {
    console.log("formData", formData);
    const response = await graphFun(formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language: '',
    metrics: [],
    keyStats: [],
    overview: [],
    graphData: []
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(metricsFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(metricsFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.metrics = action.payload;
      })
      .addCase(metricsFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
      .addCase(keyStatsFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(keyStatsFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.keyStats = action.payload;
      })
      .addCase(keyStatsFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
      .addCase(overviewFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(overviewFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.overview = action.payload;
      })
      .addCase(overviewFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
      .addCase(getGraphData.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.graphData = action.payload;
      })
      .addCase(getGraphData.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default dashboardSlice.reducer;

