import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { headerWithDatePickerData } from "../../../data/data";
const HeadingWithDatePicker = ({ heading, event, image }) => {
  const [state, setState] = React.useState("This week");
  return (
    <>
      {heading}
      {/* <Dropdown>
        <Dropdown.Toggle>
          {state}
          <img src={image ? image : "assets/images/calendar.png"} alt="icon" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {headerWithDatePickerData.map((item) => {
            return (
              <Dropdown.Item
                onClick={() => {
                  event.onclick(item);
                  setState(item);
                }}
              >
                {item}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown> */}
    </>
  );
};

export default HeadingWithDatePicker;
