import axios from "axios";
import React, { useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
const Tags = ({
  tags,
  setTags,
  dltTag,
  setDltTag,
  addedTags,
  setAddedTags,
  DataTags,
}) => {
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    if (dltTag) {
      const dlttag = DataTags.find((item, index) => index === i);
      setDltTag([...dltTag, dlttag.id]);
    }
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    if (addedTags) {
      setAddedTags([...addedTags, tag]);
    }
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {};

  return (
    <div>
      <div>
        <ReactTags
          tags={tags}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="top"
          autofocus={false}
        />
      </div>
    </div>
  );
};

export default Tags;
