import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  BaseUrl,
  branchColumn,
  businessUserColumn,
  locationColumn,
  logsColumn,
  rechargeColumn,
  searchColumn,
  singlePendingApprovalUser,
  staffColumn,
} from "../../../data/data";
import { Link, useParams } from "react-router-dom";
import {
  getBusinessUserProfile,
  getChildBranches,
  getLocation,
  getRecharges,
  getSearchLogs,
  getSingleCustomer,
  getStaff,
  getUserLogs,
} from "../../../Reducer/user/userSlice";
import { Card } from "react-bootstrap";
import BasicTable from "../../Tools/Table";

const CustomerProfile = () => {
  const { id } = useParams();
  const {
    success,
    submitting,
    error,
    partner_profile,
    child_branches,
    location,
    search,
    staff,
    recharges,
    logs,
    single_customer,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const imageObject = [
    "addhaar_front_image",
    "addhaar_back_image",
    "pan_number_front_image",
    "gst_number_certificate_image",
    "goverment_authorize_image",
  ];

  const infoObj = {};
  const imgObj = {};

  const splitObj = () => {
    Object.keys(single_customer)?.map((key) => {
      if (imageObject.includes(key)) {
        imgObj[key] = single_customer[key];
      } else {
        infoObj[key] = single_customer[key];
      }
    });
  };
  splitObj();

  React.useEffect(() => {
    dispatch(getSingleCustomer(id));
    // dispatch(getBusinessUserProfile(id));
    // dispatch(getUserLogs(id));
  }, []);

  console.log(single_customer, "SINGLE CUSTOMER");
  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  return (
    <div className="dt-table admin">
      <Card>
        <div className="worked mt-0 single_profile_freelancers">
          <div className="container_fluid">
            <div className="row">
              {/* {dashBoardImage.map((img, i) => (
                    <DashboardBox
                      key={i}
                      title={"Show Up Ratio"}
                      value={98}
                      image={img}
                      mainStyle={"col-sm-6 col-lg-4 col-xl-3 mb-4"}
                    />
                  ))} */}
            </div>
          </div>
        </div>
        <div
          className="userdiv userdivsing_pro mb-3"
          style={{ backgroundImage: "url(images/user.png)" }}
        >
          <div className="row">
            <div className="col-sm-3">
              <div className="img w-100">
                <img
                  className="w-100"
                  src={`${BaseUrl}/${single_customer.profile_image}`}
                  alt="userimg"
                />
              </div>
            </div>
            <div className="col-sm-9 text-start">
              <h2 className="pt-2 pb-2">
                {partner_profile.name}
                <Link to={`/admin/edit-user/${id}`} className="edit">
                  Edit
                </Link>
              </h2>
              <div className="col-sm-8">
                <div>
                  {Object.keys(single_customer).map((item) => (
                    <div className="col-sm-8">
                      <p>
                        <span>{item}</span>:{single_customer[item]}
                      </p>
                    </div>
                  ))}
                  {/* {Object.keys(imgObj).map((item) => (
                    <div className="col-sm-8">
                      <p>
                        <span>{item}</span>:
                        <img
                          height="50px"
                          src={`${BaseUrl}/${single_customer[item]}`}
                        />
                      </p>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="tab1-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab1-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab1-tab-pane"
              aria-selected="true"
              onClick={() => dispatch(getUserLogs(id))}
            >
              Logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab6-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab6-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab6-tab-pane"
              aria-selected="false"
              onClick={() => {
                dispatch(getChildBranches(id));
              }}
            >
              Branches
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab2-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab2-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab2-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getLocation(id))}
            >
              Locations
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab3-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab3-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab3-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getRecharges(id))}
            >
              Recharge logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab4-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab4-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab4-tab-pane"
              aria-selected="false"
            >
              Queries logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab5-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab5-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab5-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getSearchLogs(id))}
            >
              Search logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab7-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab7-tab-pane"
              type="button"
              role="tab"
              aria-controls="tab7-tab-pane"
              aria-selected="false"
              onClick={() => dispatch(getStaff(id))}
            >
              Staff
            </button>
          </li>
        </ul>
        <div
          className="tab-content userdiv userdiv2 text-start"
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active"
            id="tab1-tab-pane"
            role="tabpanel"
            aria-labelledby="tab1-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={logsColumn} rows={logs} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab6-tab-pane"
            role="tabpanel"
            aria-labelledby="tab6-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable rows={child_branches} columns={branchColumn} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab2-tab-pane"
            role="tabpanel"
            aria-labelledby="tab2-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable rows={location} columns={locationColumn} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab3-tab-pane"
            role="tabpanel"
            aria-labelledby="tab3-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={rechargeColumn} rows={recharges} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab4-tab-pane"
            role="tabpanel"
            aria-labelledby="tab4-tab"
            tabIndex={0}
          >
            <div className="freelancers ">
              <div className="inset_admin p-0">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">NO</th>
                        <th scope="col">Name</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {tableData.map((el, i) => (
                            <tr>
                              <td>1</td>
                              <td>Employer 1</td>
                              <td> June 12, 2023 </td>
                              <td>10:00 - 19:00</td>
                              <td>
                                <a href="#" className="badge text-warning">
                                  Pending
                                </a>
                              </td>
                            </tr>
                          ))} */}
                    </tbody>
                  </table>
                </div>
                <div className="pagi">
                  <nav
                    aria-label="Page navigation example"
                    className="d-inline-block"
                  >
                    <ul className="pagination mb-0">
                      <li className="page-item me-3">
                        <a className="page-link" href="#">
                          Previous page
                        </a>
                      </li>
                      <li className="page-item num active">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li className="page-item num">
                        <a className="page-link" href="#">
                          6
                        </a>
                      </li>
                      <li className="page-item ms-3">
                        <a className="page-link" href="#">
                          Next page
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab5-tab-pane"
            role="tabpanel"
            aria-labelledby="tab5-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={searchColumn} rows={search} />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="tab7-tab-pane"
            role="tabpanel"
            aria-labelledby="tab7-tab"
            tabIndex={0}
          >
            <div className="row">
              <BasicTable columns={staffColumn} rows={staff} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default CustomerProfile;
