const EditForm = ({onHide}) => {
    return (<form><div class="dard">
        <h6>Drag and drop image or <span style={{color: "#4C7EE8"}}>Browse</span></h6>
        <p>Accepts <b>JPG, PNG & PDF</b> <span>Max File Size <b>5 MB</b></span></p>
    </div>
        <label>Uploaded file</label>
        <div class="imgst">
            <span class="img"><img src="assets/images/recently_viewed1.jpg" alt="blog1" class="h-100" /></span>
            <p>cover.jpg</p>
            <span class="trsh"><img src="assets/images/trash.png" alt="icon" /></span>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={onHide}>{"Close"}</button>
            <button type="button" class="btn btn-primary" onClick={onHide}>Upload image</button>
        </div>
    </form>)
}

export default EditForm;
