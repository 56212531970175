import React from "react";
import LiveTrafffic from "./liveTraffic";
import Metrics from "./metrics";
import KeyStats from "./keyStats";
import Overview from "./overview";

document.title="Dashboard";

const DashboardPage = () => {

    return (<>
        <Overview />
        <KeyStats />
        <Metrics />
        <LiveTrafffic />
    </>
    );
}

export default DashboardPage