import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import FormGroupOption from "../../Tools/FormGroupOption";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../../../Reducer/serviceLocation/serviceLocationSlice";

const EditLocation = () => {
  const [area, setArea] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const { register, setValue, handleSubmit } = useForm({});
  const options = ["enable", "disable"];
  const [lat, setLat] = useState();
  const [long, setLong] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { submitting, success, error } = useSelector(
    (state) => state.serviceLocation
  );

  useEffect(() => {
    if (location.state) {
      setArea(`${location.state.city},${location.state.state}`);
      setState(location.state.state);
      setCity(location.state.city);
      setValue("status", location.state.status);
    }
  }, []);

  const getLatLong = (geo) => {
    const lat = geo?.location?.lat();
    const long = geo?.location?.lng();
    setLat(lat);
    setLong(long);
  };

  const handlePlace = (place) => {
    getLatLong(place?.geometry);
    if (place.address_components) {
      setCity(place?.address_components[0]?.long_name);
      if (
        place?.address_components[2].types[0] == "administrative_area_level_1"
      ) {
        setState(place?.address_components[2]?.long_name);
      } else {
        setState(place?.address_components[3]?.long_name);
      }
    } else {
      console.log("Place not found");
    }
  };

  const handleEdit = (data) => {
    const body = {
      state: state,
      city: city,
      ...data,
      latitude: lat,
      longitude: long,
    };
    dispatch(updateLocation({ id, body }));
    if (success) {
      navigate("/service-location");
    }
  };
  console.log(id);
  return (
    <div className="edit-location">
      <h3>Edit Location</h3>
      <form onSubmit={handleSubmit(handleEdit)}>
        <Autocomplete
          onPlaceSelected={handlePlace}
          options={{
            types: ["(cities)"],
            componentRestrictions: { country: "in" },
          }}
          defaultValue={area}
        />
        <FormGroupOption
          label={"Status"}
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          name={"status"}
          event={register}
          options={options}
        />
        <Button type="submit">Save Changes</Button>
      </form>
    </div>
  );
};

export default EditLocation;
