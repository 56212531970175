const BasicDetails = ({ heading, row, parentStyle, childStyle, column }) => {
  console.log("rowBasic", row);
    return (
        <div class={parentStyle}>
            <h3>{heading}</h3>
            <div class="row">
                {row && column.length > 0 && column.map((item) => {
                    return (<div class={item.style ? item.style : childStyle}>
                        <h6>{item.label}</h6>
                        <p>{item.name == "full_name" ? row[item.name]?.English : row[item.name]}</p>
                    </div>)
                })}
            </div>
        </div>
    )
}
export default BasicDetails;