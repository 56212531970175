import React from "react";
import swal from "sweetalert";
import BoostrapModel from "../Tools/BoostrapModel";

export const fetchView = (
  title,
  action,
  viewNavigate,
  dispatch,
  navigate,
  image,
  type
) => {
  switch (type) {
    case "dispatch":
      return {
        label: title,
        image: image,
        onclick: (id) => {
          const body = {
            id,
          };
          dispatch(action(body));
          navigate(`/${viewNavigate}/${id}`);
        },
      };
    case "view":
      return {
        label: title,
        image: image,
        onclick: (id) => {
          const body = {
            id,
          };
          navigate(`/${viewNavigate}/${id}`);
        },
      };
  }
  return {
    label: title,
    image: image,
    onclick: (id) => {
      const body = {
        id,
      };
      dispatch(action(body));
      navigate(`/${viewNavigate}/${id}`);
    },
  };
};

export const fetchDelete = (title, action, dispatch, image, fetchUser) => {
  return {
    label: title,
    image: image,
    onclick: async (id) => {
      try {
        const formData = new FormData();
        formData.append("id", id);
        const response = await action(formData);
        if (response.status) {
          swal("success", response.message, "success");
          dispatch(fetchUser());
        }
      } catch (e) {
        console.log("error", e);
      }
    },
  };
};
