import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  DashboardPage,
  SendNotification,
  PagesPage,
  BannerImages,
  BlockWords,
  AccountDetails,
  SinglePendingApprovalUser,
  EditPage,
  UserAccount,
  Pricing,
  Profile,
  Login,
} from "../Components";
import Sidebar from "../Components/Layouts/Sidebar";
import EditProfile from "../Components/Pages/Profile/EditProfile";
import BusinessUsers from "../Components/Pages/BusinessUser";
import BusinessProfile from "../Components/Pages/BusinessProfile";
import CustomerProfile from "../Components/Pages/CustomerProfile";
import EditBranch from "../Components/Pages/editBranch";
import AuthRoute from "../AuthRoute";
import Specialization from "../Components/Pages/Specialization";
import PendingRequest from "../Components/Pages/PendingRequest";
import PendingRequestDetail from "../Components/Pages/PendingRequestDetail";
import Selection from "../Components/Pages/Selection";
import ServiceLocation from "../Components/Pages/SeviceLocation";
import Blogs from "../Components/Pages/Blogs";
import EditLocation from "../Components/Pages/SeviceLocation/editLocation";
import AddBlogs from "../Components/Pages/Blogs/AddBlogs";
import Testimonials from "../Components/Pages/Testimonials";
import AddLocation from "../Components/Pages/SeviceLocation/addLocation";
import Category from "../Components/Pages/Category";
import SubCategory from "../Components/Pages/SubCategory";
import EditBlogs from "../Components/Pages/Blogs/EditBlogs";
import HelpCategory from "../Components/Pages/HelpCategory";
import Help from "../Components/Pages/Help";
import AddHelp from "../Components/Pages/Help/AddHelp";
import EditHelp from "../Components/Pages/Help/EditHelp";
import AddExpert from "../Components/Pages/AddExpert";

const RouteWebPage = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <AuthRoute element={<Sidebar component={<DashboardPage />} />} />
        }
      />
      <Route
        exact
        path="/business-users"
        element={
          <AuthRoute element={<Sidebar component={<BusinessUsers />} />} />
        }
      />
      <Route
        exact
        path="/help-category"
        element={
          <AuthRoute element={<Sidebar component={<HelpCategory />} />} />
        }
      />
      <Route
        exact
        path="/user-help/:id"
        element={
          <AuthRoute
            element={<Sidebar component={<EditHelp type="user" />} />}
          />
        }
      />
      <Route
        exact
        path="/business-help/:id"
        element={
          <AuthRoute
            element={<Sidebar component={<EditHelp type="business" />} />}
          />
        }
      />
      <Route
        exact
        path="/user-help"
        element={
          <AuthRoute element={<Sidebar component={<Help type="user" />} />} />
        }
      />
      <Route
        exact
        path="/user-help/addhelp"
        element={
          <AuthRoute
            element={<Sidebar component={<AddHelp type="user" />} />}
          />
        }
      />
      <Route
        exact
        path="/business-help/addhelp"
        element={
          <AuthRoute
            element={<Sidebar component={<AddHelp type="business" />} />}
          />
        }
      />
      <Route
        exact
        path="/business-help"
        element={
          <AuthRoute
            element={<Sidebar component={<Help type="business" />} />}
          />
        }
      />
      <Route
        exact
        path="/pages"
        element={<AuthRoute element={<Sidebar component={<PagesPage />} />} />}
      />
      <Route
        exact
        path="/block_words"
        element={<AuthRoute element={<Sidebar component={<BlockWords />} />} />}
      />
      <Route
        exact
        path="/business-profile/:id"
        element={
          <AuthRoute element={<Sidebar component={<BusinessProfile />} />} />
        }
      />
      <Route
        exact
        path="/edit_page/:id"
        element={<AuthRoute element={<Sidebar component={<EditPage />} />} />}
      />
      <Route
        exact
        path="/user_accounts"
        element={
          <AuthRoute element={<Sidebar component={<UserAccount />} />} />
        }
      />
      <Route
        exact
        path="/account_details/:id"
        element={
          <AuthRoute element={<Sidebar component={<AccountDetails />} />} />
        }
      />
      <Route
        exact
        path="/send_notifications"
        element={
          <AuthRoute element={<Sidebar component={<SendNotification />} />} />
        }
      />
      <Route
        exact
        path="/send_email"
        element={
          <AuthRoute
            element={<Sidebar component={<h3>Page Not Found</h3>} />}
          />
        }
      />
      <Route
        exact
        path="/pricing_controls"
        element={<AuthRoute element={<Sidebar component={<Pricing />} />} />}
      />
      <Route
        exact
        path="/customer-profile/:id"
        element={
          <AuthRoute element={<Sidebar component={<CustomerProfile />} />} />
        }
      />
      <Route
        exact
        path="/edit-branch/:id"
        element={<AuthRoute element={<Sidebar component={<EditBranch />} />} />}
      />
      <Route
        exact
        path="/banner_images"
        element={
          <AuthRoute element={<Sidebar component={<BannerImages />} />} />
        }
      />
      <Route
        exact
        path="/profile"
        element={<AuthRoute element={<Sidebar component={<Profile />} />} />}
      />
      <Route
        exact
        path="/EditProfile"
        element={
          <AuthRoute element={<Sidebar component={<EditProfile />} />} />
        }
      />
      <Route
        exact
        path="/specialization"
        element={
          <AuthRoute element={<Sidebar component={<Specialization />} />} />
        }
      />
      <Route
        exact
        path="/pending-requests"
        element={
          <AuthRoute element={<Sidebar component={<PendingRequest />} />} />
        }
      />
      <Route
        exact
        path="/pending-request/:id"
        element={
          <AuthRoute
            element={<Sidebar component={<PendingRequestDetail />} />}
          />
        }
      />
      <Route
        exact
        path="/document-selection"
        element={<AuthRoute element={<Sidebar component={<Selection />} />} />}
      />
      <Route
        exact
        path="/service-location"
        element={
          <AuthRoute element={<Sidebar component={<ServiceLocation />} />} />
        }
      />
      <Route
        exact
        path="/blogs"
        element={<AuthRoute element={<Sidebar component={<Blogs />} />} />}
      />
      <Route
        exact
        path="/service-location/:id"
        element={
          <AuthRoute element={<Sidebar component={<EditLocation />} />} />
        }
      />
      <Route
        exact
        path="/add-blog"
        element={<AuthRoute element={<Sidebar component={<AddBlogs />} />} />}
      />
      <Route
        exact
        path="/testimonials"
        element={
          <AuthRoute element={<Sidebar component={<Testimonials />} />} />
        }
      />
      <Route
        exact
        path="/add-newlocation"
        element={
          <AuthRoute element={<Sidebar component={<AddLocation />} />} />
        }
      />
      <Route
        exact
        path="/edit-location/:id"
        element={
          <AuthRoute element={<Sidebar component={<EditLocation />} />} />
        }
      />
      <Route
        exact
        path="/categories"
        element={<AuthRoute element={<Sidebar component={<Category />} />} />}
      />
      <Route
        exact
        path="/sub-categories/:id"
        element={
          <AuthRoute element={<Sidebar component={<SubCategory />} />} />
        }
      />
      <Route
        exact
        path="/edit-blog/:slug"
        element={<AuthRoute element={<Sidebar component={<EditBlogs />} />} />}
      />
      <Route
        exact
        path="/add-expert"
        element={<AuthRoute element={<Sidebar component={<AddExpert />} />} />}
      />
    </Routes>
  );
};

export default RouteWebPage;
