import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading"
import SearchBar from "../../Tools/SearchBar"
import BasicTable from "../../Tools/Table"
import TradeBox from "../../Tools/TradeBox"

const Transcation = ({rows, columns, rowInformation, action}) => {
    return (<div class="set_list2">
        <h3 class="thr">
            Transaction by ad
            <ul>
                <li>
                    <SearchBar />
                </li>
                <li>
                    <select class="form-control">
                        <option>All status</option>
                        <option>Status</option>
                        <option>Status2</option>
                    </select>
                </li>
                <li>
                    <HeadingWithDatePicker image={"../assets/images/calendar.png"} />
                </li>
            </ul>
        </h3>
        <BasicTable rows={rows} columns={columns} rowInformation={rowInformation} actionType={"dropdown"} actions={action} dropDownImage={"../assets/images/dash_img/threedot.png"} />
        </div>)
}

export default Transcation