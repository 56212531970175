import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import {
  editPartnerUser,
  getBusinessUserProfile,
} from "../../../Reducer/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import moment from "moment";

const EditBranch = () => {
  const [editForm, setEditForm] = useState({
    company_name: "",
    owner_name: "",
    phone: "",
    latitude: "",
    longitude: "",
    permanent_address: "",
    authorize_expiery: "",
    type: "",
    pan_card: "",
    company_logo: "",
    authorize_certificate: "",
    gst_certificate: "",
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, success, partner_profile } = useSelector(
    (state) => state.user
  );
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({});

  useEffect(() => {
    dispatch(getBusinessUserProfile(id));
  }, [dispatch, id]);

  useEffect(() => {
    setEditForm({
      ...partner_profile,
      authorize_expiery: new Date(partner_profile.authorize_expiery / 1000)
        .toISOString()
        .split("T")[0],
    });
  }, [partner_profile]);

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => {
          setEditForm({
            ...editForm,
            latitude: placeDetails.geometry.location.lat(),
            longitude: placeDetails.geometry.location.lng(),
          });
        }
      );
  }, [placePredictions]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      setEditForm({ ...editForm, [name]: e.target.files[0] });
    } else {
      setEditForm({ ...editForm, [name]: value });
    }
  };

  const onSubmitform = async (e) => {
    e.preventDefault();
    const unixTime = moment(editForm.authorize_expiery, "MM/DD/YYYY").unix();
    const formData = new FormData();
    formData.append("uid", partner_profile.uid);
    formData.append("company_name", editForm.company_name);
    formData.append("owner_name", editForm.owner_name);
    formData.append("permanent_address", editForm.permanent_address);
    formData.append("latitude", editForm.latitude);
    formData.append("longitude", editForm.longitude);
    formData.append("type", editForm.type);
    formData.append("phone", editForm.phone);
    formData.append("authorize_expirey", unixTime);
    formData.append("company_logo", editForm.company_logo);
    formData.append("pan_card", editForm.pan_card);
    formData.append("authorize_certificate", editForm.authorize_certificate);
    formData.append("gst_certificate", editForm.gst_certificate);
    dispatch(editPartnerUser({ id, formData }));
    if (success) {
      navigate(`/business-profile/${id}`);
    }
  };
  return (
    <div>
      <h6>Edit Branch</h6>
      <div>
        <form className="form-signin" onSubmit={onSubmitform}>
          <div className="form-group">
            <label>Company Name</label>
            <input
              className={"form-control"}
              type={"text"}
              value={editForm.company_name}
              onChange={handleChange}
              name={"company_name"}
              placeholder={"Enter company name"}
            />
          </div>
          <div className="form-group">
            <label>Owner Name</label>
            <input
              className={"form-control"}
              type={"text"}
              value={editForm.owner_name}
              onChange={handleChange}
              name={"owner_name"}
              placeholder={"Enter owner name"}
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              className={"form-control"}
              type={"number"}
              value={editForm.phone}
              onChange={handleChange}
              name={"phone"}
              placeholder={"Enter phone number"}
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              className={"form-control"}
              type={"text"}
              name={"permanent_address"}
              value={editForm.permanent_address}
              onChange={(e) => {
                handleChange(e);
                getPlacePredictions({ input: e.target.value });
              }}
              placeholder={"Enter Address"}
            />
            {placePredictions.map((item) => (
              <p
                onClick={() => {
                  setEditForm({
                    ...editForm,
                    permanent_address: item.description,
                  });
                  getPlacePredictions({ input: item.description });
                }}
              >
                {item.description}
              </p>
            ))}
          </div>
          {/* <div className="form-group">
            <label>Latitude</label>
            <input
              className={"form-control"}
              type={"number"}
              name={"latitude"}
              value={editForm.latitude}
              onChange={handleChange}
              placeholder={"Enter Latitude"}
            />
          </div> */}
          {/* <div className="form-group">
            <label>Longitude</label>
            <input
              className={"form-control"}
              type={"number"}
              name={"longitude"}
              value={editForm.longitude}
              onChange={handleChange}
              placeholder={"Enter Longitude"}
            />
          </div> */}
          <div className="form-group">
            <label>Type</label>
            <input
              type="radio"
              name="type"
              value={"institute"}
              onChange={handleChange}
              checked={editForm.type == "institute"}
            />{" "}
            Institute
            <input
              type="radio"
              name="type"
              value={"immigration"}
              onChange={handleChange}
              checked={editForm.type == "immigration"}
            />{" "}
            Immigration
          </div>
          <div className="form-group">
            <label>Authorize Expiry</label>
            <input
              className="form-control"
              type="date"
              name="authorize_expiery"
              value={editForm.authorize_expiery}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Company Logo</label>
            <input
              className={"form-control"}
              type={"file"}
              name={"company_logo"}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Pan Image</label>
            <input
              className={"form-control"}
              type={"file"}
              name={"pan_number_front_image"}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Goverment Authorize Image</label>
            <input
              className={"form-control"}
              type={"file"}
              name={"authorize_certificate"}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Gst Number Certificate Image</label>
            <input
              className={"form-control"}
              type={"file"}
              name={"gst_certificate"}
              onChange={handleChange}
            />
          </div>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};
export default EditBranch;
