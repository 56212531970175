import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { Button } from "react-bootstrap";
import { addSubCategories } from "../../../Reducer/category/categorySlice";

const AddSubCategory = ({ onHide, parent_id }) => {
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const body = { ...data, parent_id: parent_id };
    dispatch(addSubCategories(body));
    onHide();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroupLabelInput
          label={"Sub Category Name"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          name={"name"}
          event={register}
        />
        <div className="modal-footer"></div>
        <Button type="submit">Add</Button>
      </form>
    </div>
  );
};

export default AddSubCategory;
