import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSpecialization,
  editSpecialization,
  getSpecialization,
} from "../../services/api";

export const addSpecializationFun = createAsyncThunk(
  "/addspecialization",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addSpecialization(formData);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const getSpecializationFun = createAsyncThunk(
  "/getspecialization",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSpecialization();
      return response.data;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const editSpecializationFun = createAsyncThunk(
  "/editspecialization",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, "F");
      const response = await editSpecialization(data.id, data.data);
      console.log(response, "RES");
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);
const specializationSlice = createSlice({
  name: "specialization",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    specializations: [],
  },
  extraReducers: (buider) => {
    buider
      .addCase(addSpecializationFun.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addSpecializationFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
      })
      .addCase(addSpecializationFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getSpecializationFun.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSpecializationFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
        state.specializations = action.payload;
      })
      .addCase(getSpecializationFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(editSpecializationFun.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editSpecializationFun.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.error = null;
      })
      .addCase(editSpecializationFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default specializationSlice.reducer;
