import React, { useEffect } from 'react'
import MyprofileDetails from './MyprofileDetails'
import { myprofile } from '../../../Reducer/profileSilce/profileSilce'
import { useDispatch, useSelector } from 'react-redux';

document.title="Profile";

export default function Profile() {
    const data = useSelector(state => state.profile.users);
    const dispatch = useDispatch();
      useEffect(() => {
        dispatch(myprofile());
      }, [dispatch]);
  return (
    <div>
       <MyprofileDetails data={data.profile ? data.profile : {}}/>
    </div>
  )
}
