import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addBranch, updateBranchPrimaryDetails } from "../../services/api";

export const addBranchForm = createAsyncThunk(
  "branch/addbranch",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addBranch(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editPrimaryDetails = createAsyncThunk(
  "branch/edit-primary-details",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateBranchPrimaryDetails(data.id, data.data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    submitting: false,
    success: false,
    error: null,
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      .addCase(addBranchForm.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addBranchForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(addBranchForm.rejected, (state, action) => {
        state.error = action.payload;
        state.success = false;
        state.submitting = false;
      })
      .addCase(editPrimaryDetails.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editPrimaryDetails.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = false;
      })
      .addCase(editPrimaryDetails.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default branchSlice.reducer;
