import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPendingRequestFun,
  updatePendingRequestFun,
} from "../../services/api";
import { error } from "jquery";

export const fetchPendingRequest = createAsyncThunk(
  "get_pending_request",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPendingRequestFun();
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const updatePendingRequest = createAsyncThunk(
  "update_request",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updatePendingRequestFun(data.id, data.data);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const PendingRequestSlice = createSlice({
  name: "pending_request",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    pending_requests: [],
  },
  extraReducers: (buider) =>
    buider
      .addCase(fetchPendingRequest.pending, (state, action) => {
        state.submitting = true;
        state.success = false;
        state.error = null;
      })
      .addCase(fetchPendingRequest.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.pending_requests = action.payload;
        state.error = null;
      })
      .addCase(fetchPendingRequest.rejected, (state, action) => {
        state.submitting = false;
        state.success = false;
        state.error = action.payload;
      }),
  // .addCase(updatePendingRequest.pending, (state) => {
  //   state.submitting = true;
  //   state.success = false;
  //   state.error = null;
  // })
  // .addCase(updatePendingRequest.fulfilled, (state, action) => {
  //   state.submitting = false;
  //   state.success = true;
  //   state.error = null;
  // })
  // .addCase(updatePendingRequest.rejected, (state, action) => {
  //   state.submitting = false;
  //   state.error = action.payload;
  //   state.success = false;
  // }),
});

export default PendingRequestSlice.reducer;
