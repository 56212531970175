import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { addExpertFun, getExpertFun } from "../../../services/api";
import BasicTable from "../../Tools/Table";
import { expertsColumn } from "../../../data/data";

const AddExpert = () => {
  const [show, setShow] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [experts, setExperts] = useState([]);

  const getLatLong = (geo) => {
    const lat = geo?.location?.lat();
    const long = geo?.location?.lng();
    setLat(lat);
    setLong(long);
  };

  const handlePlace = (place) => {
    getLatLong(place?.geometry);
    setAddress(place.formatted_address);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
    if (isNaN(e.target.value)) {
      setError("Alphabets not allowed");
    } else if (e.target.value.length > 10) {
      setError("phone must be at most 10 digits ");
    } else {
      setError(null);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!error) {
        let body = {
          name: name,
          current_address: address,
          latitude: lat,
          longitude: long,
          phone: phone,
        };
        const responce = await addExpertFun(body);
        if (responce.status === 200) {
          setAddress("");
          setLat("");
          setLong("");
          setPhone("");
          setName("");
          setShow(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExperts();
  }, []);

  const getExperts = async () => {
    try {
      const responce = await getExpertFun();
      if (responce && responce.data) {
        setExperts(responce.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h3>
        Experts
        <Button variant="primary" size="sm" onClick={() => setShow(true)}>
          Add Expert
        </Button>
      </h3>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="full_name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" value={phone} onChange={handlePhone} />
            </Form.Group>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Autocomplete
                onPlaceSelected={handlePlace}
                options={{
                  types: ["(cities)"],
                  componentRestrictions: { country: "in" },
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <BasicTable columns={expertsColumn} rows={experts ? experts : []} />
    </div>
  );
};

export default AddExpert;
