import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addServiceLocationFun,
  editLocationFun,
  getServiceLocationFun,
} from "../../services/api";

export const addNewLocation = createAsyncThunk(
  "/addLocation",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addServiceLocationFun(formData);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const getAllLocation = createAsyncThunk(
  "/getlocation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getServiceLocationFun();
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const updateLocation = createAsyncThunk(
  "updateLocation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editLocationFun(data.id, data.body);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const serviceLocationSlice = createSlice({
  name: "servicelocation",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    all_locations: [],
  },
  extraReducers: (buider) => {
    buider
      .addCase(addNewLocation.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addNewLocation.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(addNewLocation.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(getAllLocation.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllLocation.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.all_locations = action.payload;
      })
      .addCase(getAllLocation.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(updateLocation.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default serviceLocationSlice.reducer;
