import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSelectionDocFun,
  editSelectionDocFun,
  getSelectionDocFun,
} from "../../services/api";

export const fetchAllDocSelection = createAsyncThunk(
  "/get_selections",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSelectionDocFun();
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const addSelectionDoc = createAsyncThunk(
  "/add_selection",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addSelectionDocFun(formData);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const editSelectionDoc = createAsyncThunk(
  "edit_selection",
  async (data, { rejectWithValue }) => {
    try {
      const response = await editSelectionDocFun(data.id, data.data);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);
const selectionSlice = createSlice({
  name: "selection",
  initialState: {
    submitting: false,
    success: false,
    error: null,
    all_selections: [],
  },
  extraReducers: (buider) => {
    buider
      .addCase(fetchAllDocSelection.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchAllDocSelection.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
        state.all_selections = action.payload;
      })
      .addCase(fetchAllDocSelection.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(addSelectionDoc.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addSelectionDoc.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(addSelectionDoc.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(editSelectionDoc.pending, (state, action) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editSelectionDoc.fulfilled, (state, action) => {
        state.submitting = false;
        state.error = null;
        state.success = true;
      })
      .addCase(editSelectionDoc.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default selectionSlice.reducer;
