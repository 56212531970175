import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { addNewLocation } from "../../../Reducer/serviceLocation/serviceLocationSlice";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AddLocation = () => {
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [lat, setLat] = useState();
  const [long, setLong] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, submitting, error } = useSelector(
    (state) => state.serviceLocation
  );

  const getLatLong = (geo) => {
    const lat = geo?.location?.lat();
    const long = geo?.location?.lng();
    setLat(lat);
    setLong(long);
  };

  const handlePlace = (place) => {
    getLatLong(place.geometry);
    if (place.address_components) {
      setCity(place?.address_components[1]?.long_name);
      if (
        place?.address_components[2].types[0] == "administrative_area_level_1"
      ) {
        setState(place?.address_components[2]?.long_name);
      } else {
        setState(place?.address_components[3]?.long_name);
      }
    } else {
      console.log("Place not found");
    }
  };

  const handleClick = () => {
    const body = { state, city, latitude: lat, longitude: long };
    dispatch(addNewLocation(body));
    if (success) {
      navigate("/service-location");
    }
  };

  return (
    <div>
      <h3>Add New Location</h3>
      <Autocomplete
        onPlaceSelected={handlePlace}
        options={{
          types: ["(cities)"],
          componentRestrictions: { country: "in" },
        }}
      />
      <Button variant="primary" size="sm" onClick={handleClick}>
        Add Service Location
      </Button>
    </div>
  );
};

export default AddLocation;
