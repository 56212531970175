import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingRequest } from "../../../Reducer/PendingRequest/pendingRequestSlice";
import BasicTable from "../../Tools/Table";
import { pending_requestColumn } from "../../../data/data";

const PendingRequest = () => {
  const dispatch = useDispatch();
  const { error, submitting, pending_requests } = useSelector(
    (state) => state.pending_request
  );

  useEffect(() => {
    dispatch(fetchPendingRequest());
  }, [dispatch]);

  const view = {
    label: "View",
    image: "eye2.png",
    path: "/pending-request",
  };

  return (
    <div className="overview">
      <h3>Pending Request</h3>
      <BasicTable
        rows={pending_requests ? pending_requests : []}
        columns={pending_requestColumn}
        view={view}
      />
    </div>
  );
};

export default PendingRequest;
