import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPartnerUser } from "../../../Reducer/user/userSlice";
import { businessUserColumn, frontUrl } from "../../../data/data";
import BasicTable from "../../Tools/Table";
import { status } from "../../../data/data";
import { Button, Spinner } from "react-bootstrap";
import AddBranch from "../AddBranch";
import TopbarWithDateandSearch from "../../Tools/TopbarWithDateandSearch";

const BusinessUsers = () => {
  const [show, setShow] = useState(false);
  const { error, success, submitting, partner_users } = useSelector(
    (state) => state.user
  );

  const [filterData, setFilterData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartnerUser());
  }, [dispatch]);

  useEffect(() => {
    setFilterData(partner_users);
  }, [partner_users]);

  const view = {
    label: "View",
    image: "eye2.png",
    path: "/business-profile",  
  };
  // const onsubmit = (data) => {
  //     const formData = new FormData();
  //     formData.append('time', data);
  //     dispatch(getAllUserFun(formData));
  // }

  const searchBar = (data) => {
    const filter = partner_users?.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(data.toLowerCase()) !== -1
    );
    setFilterData(filter);
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  return (
    <div className="overview">
      <h3 className="trade">
        <TopbarWithDateandSearch
          heading={"Business Users"}
          searchOnClick={searchBar}
        />
      </h3>
      {submitting ? (
        <Spinner variant="border" />
      ) : (
        <>
          <Button onClick={() => setShow(true)}>Add Branch</Button>
          <BasicTable
            actionType={"dropdown"}
            columns={businessUserColumn ? businessUserColumn : []}
            rows={filterData}
            view={view}
            businessUserStatus={status}
            url={frontUrl}
          />
          <AddBranch show={show} setShow={setShow} />
        </>
      )}
    </div>
  );
};
export default BusinessUsers;
