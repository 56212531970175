import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormGroupOption from "../../Tools/FormGroupOption";
import {
  editSubscriptonAnalytics,
  editSubscriptonCallType,
  editSubscriptonChatType,
  editSubscriptonDiscount,
  editSubscriptonDuration,
  editSubscriptonPrice,
  editSubscriptonStatus,
} from "../../../data/data";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import Button from "../../Tools/Button";
import { editSubscriptionFun } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { editSubPlan } from "../../../Reducer/subscription/subscriptionSlice";

const EditForm = ({ onHide, id }) => {
  const { register, handleSubmit, setValue } = useForm();
  const { all_plans } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();

  useEffect(() => {
    const filterData = all_plans.find((item) => item.id === id);
    setValue("title", filterData.title);
    setValue("description", filterData.description);
    setValue("type", filterData.type);
    setValue("status", filterData.status);
    setValue("chat_type", filterData.chat_type);
    setValue("call_type", filterData.call_type);
    setValue("duration", filterData.duration);
    setValue("chat_limit", filterData.chat_limit);
    setValue("call_limit", filterData.call_limit);
    setValue("amount", filterData.amount);
    setValue("analytics", filterData.analytics);
    setValue("branch_count", filterData.branch_count);
    setValue("staff_per_branch", filterData.staff_per_branch);
    setValue("priority", filterData.priority);
    setValue("service_fees", filterData.service_fees);
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("type", data.type);
      formData.append("status", data.status);
      formData.append("chat_type", data.chat_type);
      formData.append("chat_limit", data.chat_limit);
      formData.append("call_type", data.call_type);
      formData.append("call_limit", data.call_limit);
      formData.append("title", data.title);
      formData.append("duration", data.duration);
      formData.append("description", data.description);
      formData.append("amount", data.amount);
      formData.append("image", data.image[0]);
      formData.append("branch_count", data.branch_count);
      formData.append("staff_per_branch", data.staff_per_branch);
      formData.append("analytics", data.analytics);
      formData.append("priority", data.priority);
      formData.append("service_fees", data.service_fees);
      dispatch(editSubPlan({ id, formData }));
      onHide();
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      <form className="row" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-sm-12 mb-3">
          <FormGroupLabelInput
            label={"Plan name"}
            parentStyle={"form-group"}
            childStyle={"form-control"}
            event={register}
            name={"title"}
            placeholder={"Basic 2 Weeks Ad"}
          />
          <FormGroupLabelInput
            label={"Description"}
            parentStyle={"form-group"}
            childStyle={"form-control"}
            event={register}
            name={"description"}
            placeholder={"Basic 2 Weeks Ad"}
          />
        </div>
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Type"}
          name={"type"}
          event={register}
          options={editSubscriptonPrice}
        />
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Status"}
          name={"status"}
          event={register}
          options={editSubscriptonStatus}
        />
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Duration"}
          name={"duration"}
          event={register}
          options={editSubscriptonDuration}
        />
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Chat Type"}
          name={"chat_type"}
          event={register}
          options={editSubscriptonChatType}
        />
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Call Type"}
          name={"call_type"}
          event={register}
          options={editSubscriptonCallType}
        />
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"Analytics"}
          name={"analytics"}
          event={register}
          options={editSubscriptonAnalytics}
        />
        <FormGroupOption
          parentStyle={"col-sm-6 mb-3"}
          childStyle={"form-group"}
          selectStyle={"form-control"}
          label={"First Priority"}
          name={"priority"}
          event={register}
          options={editSubscriptonAnalytics}
        />
        <FormGroupLabelInput
          label={"Branch Count"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"branch_count"}
          placeholder={"0"}
        />
        <FormGroupLabelInput
          label={"Staff per branch"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"staff_per_branch"}
          placeholder={"0"}
        />
        <FormGroupLabelInput
          label={"Service fees per application"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"service_fees"}
          placeholder={"0"}
        />
        <FormGroupLabelInput
          label={"Chat Limit"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"chat_limit"}
          placeholder={"0"}
        />
        <FormGroupLabelInput
          label={"Call Limit"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"call_limit"}
          placeholder={"0"}
        />
        <FormGroupLabelInput
          label={"Amount"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"amount"}
          placeholder={"0"}
        />
        <div className="form-group">
          <label>Image</label>
          <input type="file" className="form-control" {...register("image")} />
        </div>
        <div className="col-sm-12 ">
          <div className="modal-footer">
            <Button
              type={"button"}
              style={"btn btn-secondary"}
              onClick={onHide}
              title={"Close"}
            />
            <Button
              type={"submit"}
              style={"btn btn-primary"}
              title={"Edit plan"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
