import React, { Component, useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import BasicTable from "../../Tools/Table";
import TopbarWithDateandSearch from "../../Tools/TopbarWithDateandSearch";
import { userAccountList } from "../../../Reducer/userAccount/userAccountSlice";
import { frontUrl, userAccountColumns } from "../../../data/data";
import ReasonModel from "../../Tools/ReasonModel";
import BoostrapModel from "../../Tools/BoostrapModel";
import { deleteOption, issueOption, refundOption } from "../../../data/data";
import { reportFun } from "../../../services/api";
import { getAllUserFun } from "../../../Reducer/user/userSlice";
import { status } from "../../../data/data";
import { Spinner } from "react-bootstrap";

const UserAccount = () => {
  const { success, user, submitting } = useSelector((state) => state.user);
  const rows = useSelector((state) => state.userAccountList?.userAccountList);
  const [show, setShow] = React.useState(false);
  const [state, setState] = React.useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
  });
  const [filterData, setFilterData] = useState(user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserFun());
  }, [dispatch]);

  useEffect(() => {
    setFilterData(user);
  }, [user]);

  const searchBar = (data) => {
    console.log(data);
    const filter = user?.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(data.toLowerCase()) !== -1
    );
    setFilterData(filter);
  };

  const dateFilter = (time) => {
    console.log(time);
    const body = {
      time,
    };
    dispatch(userAccountList);
  };

  const handleSubmit = async (data) => {
    const body = {
      data,
    };
    try {
      const response = await reportFun(body);
      if (response.status) {
        setShow(!show);
        swal("success", "sucessss", "success");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const actions = [
    {
      label: "Edit",
      onclick: (id) => {
        console.log("id", id);
        setState({
          id: id,
          heading: "Delete Advert ?",
          title: "Delete Advert",
          buttonTitle: "Delete",
          option: deleteOption,
          type: "selection",
        });
        setShow(!show);
      },
    },
    {
      label: "Delete",
      onclick: (id) => {
        setState({
          id: id,
          title: "Refund for Transcation",
          heading: "Refund for Transcation",
          buttonTitle: "Refund for Transcation",
          option: refundOption,
          type: "selection",
        });
        setShow(!show);
      },
    },
  ];
  const view = {
    label: "View",
    path: "/customer-profile",
    image: "eye2.png",
  };
  console.log(filterData);
  return (
    <div className="overview">
      <TopbarWithDateandSearch
        heading={"Customer"}
        searchOnClick={searchBar}
        dateOnclick={dateFilter}
      />
      {/* <img src="../assets/images/sc1.png" onClick={()=>dispatch(getAllUserFun())}/> */}
      {submitting ? (
        <Spinner variant="border" />
      ) : (
        <>
          <BasicTable
            rows={filterData}
            columns={userAccountColumns}
            actionType={"dropdown"}
            actions={actions}
            view={view}
            status={status}
            url={frontUrl}
          />
          <BoostrapModel
            show={show}
            heading={state.heading}
            onHide={() => setShow(!show)}
            component={
              <ReasonModel
                id={state.id}
                label={state.title}
                buttonTitle={state.buttonTitle}
                option={state.option}
                type={state.type}
                handleSubmitEvent={handleSubmit}
                onHide={() => setShow(!show)}
              />
            }
          />
        </>
      )}
    </div>
  );
};
export default UserAccount;
