import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewLocation,
  getAllLocation,
} from "../../../Reducer/serviceLocation/serviceLocationSlice";
import BasicTable from "../../Tools/Table";
import { serviceLocationColumn } from "../../../data/data";
import { useNavigate } from "react-router-dom";
const ServiceLocation = () => {
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { submitting, success, error, all_locations } = useSelector(
    (state) => state.serviceLocation
  );

  useEffect(() => {
    dispatch(getAllLocation());
  }, []);

  const actions = [
    {
      label: "Edit",
      style: "Reject",
      onclick: (row) => {
        navigate(`/edit-location/${row.id}`, { state: row });
      },
      type: "text",
    },
  ];

  return (
    <div>
      <h3>
        Service Location
        <Button
          variant="primary"
          size="sm"
          onClick={() => navigate("/add-newlocation")}
        >
          Add Service Location
        </Button>
      </h3>
      <BasicTable
        rows={all_locations ? all_locations : []}
        columns={serviceLocationColumn}
        actions={actions}
      />
    </div>
  );
};

export default ServiceLocation;
