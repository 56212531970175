import ChecboxWithSpan from "../../Tools/CheckboxWithSpan"
const SendNotificationOption = () => {
    return (<p>
        <ChecboxWithSpan
            parentStyle={"checkcontainer"}
            childStyle={"radiobtn"}
            inType={"checkbox"}
            inputStyle={"quality"}
            name={"quality"}
            value={"T1"}
            title={"To App"} />
        <ChecboxWithSpan
            parentStyle={"checkcontainer"}
            childStyle={"radiobtn"}
            inType={"checkbox"}
            inputStyle={"quality"}
            name={"phone"}
            value={"T1"}
            title={"To Phone"} />
        <ChecboxWithSpan
            inStyle={{ marginRight: "0px" }}
            parentStyle={"checkcontainer"}
            childStyle={"radiobtn"}
            inType={"checkbox"}
            inputStyle={"quality"}
            name={"email"}
            value={"T1"}
            title={"To Email"} />
    </p>)
}

export default SendNotificationOption