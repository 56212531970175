const BoxItem = ({ parentStyle, childStyle, heading, data }) => {
    return (<>
        {heading && <h6>{heading}</h6>}
        <div className="row">
            {data.length > 0 && data.map((i) => {
                return (<div className={parentStyle}>
                    <div className={childStyle}>
                        <h5>{i.title}</h5>
                        <p>{i.description}</p>
                    </div>
                </div>);
            })}
        </div>
    </>)
}
export default BoxItem