import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Block from "./Block";
import { Button, Modal } from "react-bootstrap";
import {
  fetchPendingRequest,
  updatePendingRequest,
} from "../../../Reducer/PendingRequest/pendingRequestSlice";

const PendingRequestDetail = () => {
  const [pendingData, setPendingData] = useState({});
  const [allData, setAllData] = useState({});
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, success, pending_requests } = useSelector(
    (state) => state.pending_request
  );
  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setPendingData({ ...pendingData, [name]: value });
  };

  useEffect(() => {
    const data = pending_requests?.find((item) => item.id == id);
    if (!data) {
      navigate("/pending-requests");
    } else {
      console.log(data,"OOOOo")
      setAllData(data);
      setPendingData({
        type:data.type,
        owner_name_status: data.owner_name_status || "Pending",
        owner_name_comment: data.owner_name_comment,
        company_name_comment: data.company_name_comment || "",
        company_name_status: data.company_name_status || "Pending",
        address_comment: data.address_comment,
        address_status: data.address_status || "Pending",
        status: data.status,
        comment: data.comment || "",
        permanent_address:data.permanent_address
      });
    }
  }, [submitTrigger, pending_requests]);

  useEffect(() => {
    if (submitTrigger) {
      handleClick();
    }
    setSubmitTrigger(false);
  }, [submitTrigger]);

  const addData = (key, value) => {
    setPendingData({ ...pendingData, [key]: value });
  };

  const handleClick = () => {
    const data = { ...pendingData, branch_id: allData.branch_id };
    dispatch(updatePendingRequest({ id, data }));
    dispatch(fetchPendingRequest());
  };

  const onHide = () => setShow(!show);

  const handleType = (e) =>{
    setPendingData({...pendingData,type:e.target.value})
  }

  return (
    <div>
      <h3>{"Pending Request Details"}</h3>
      <div>
        <label>Type</label>
        <input type="radio" name="type" value={"immigration"} checked={pendingData.type==="immigration"} onChange={handleType} />Immigration
        <input type="radio" name="type" value={"institute"} checked={pendingData.type==="institute"} onChange={handleType}  />Institute
        <input type="radio" name="type" value={"both"}  checked={pendingData.type==="both"} onChange={handleType}  />Both  
      </div>
      <Block
        value={allData.owner_name}
        label={"Owner Name"}
        name={"owner_name"}
        branchId={allData.branch_id}
        status={allData.owner_name_status}
        statuskey={"owner_name_status"}
        commentkey={"owner_name_comment"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      />
      <Block
        value={allData.company_name}
        label={"Company Name"}
        name={"company_name"}
        branchId={allData.branch_id}
        status={allData.company_name_status}
        statuskey={"company_name_status"}
        commentkey={"company_name_comment"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      />
      <Block
        label={"Address"}
        value={allData.permanent_address}
        name={"permanent_address"}
        branchId={allData.branch_id}
        status={allData.address_status}
        statuskey={"address_status"}
        commentkey={"address_comment"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      />
      {/* <Block
        label={"Authorize Certificate"}
        value={allData.authorize_certificate}
        image={true}
        branchId={allData.branch_id}
        status={allData.authorize_certificate_status}
        commentkey={"authorize_certificate_comment"}
        statuskey={"authorize_certificate_status"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      /> */}
      {/* <Block
        label={"Pan Card"}
        value={allData.pan_card}
        image={true}
        branchId={allData.branch_id}
        commentkey={"pan_comment"}
        status={allData.pan_status}
        statuskey={"pan_status"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      /> */}
      {/* <Block
        label={"Gst Cerificate"}
        value={allData.gst_certificate}
        image={true}
        branchId={allData.branch_id}
        statuskey={"gst_status"}
        status={allData.gst_status}
        commentkey={"gst_comment"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      /> */}
      {/* <Block
        label={"Company Logo"}
        value={allData.company_logo}
        image={true}
        branchId={allData.branch_id}
        status={allData.company_logo_status}
        statuskey={"company_logo_status"}
        commentkey={"company_logo_comment"}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      /> */}
      {/* <Block
        label={" Authorize Expiry Date"}
        value={allData.authorize_expiery}
        branchId={allData.branch_id}
        statuskey={"authorize_expiery_status"}
        commentkey={"authorize_expiery_comment"}
        status={allData.authorize_expiery_status}
        addData={addData}
        setSubmitTrigger={setSubmitTrigger}
      /> */}

      {(pendingData.owner_name_status &&
        pendingData.company_name_status &&
        pendingData.company_logo_status) !== "Pending" && (
        <>
          <div className="form-group">
            <label>{"Comment"}</label>
            <input
              className="form-control"
              type="text"
              name="comment"
              onChange={handleChange}
              value={pendingData.comment}
            />
          </div>
          <div className="d-grid gap-2 my-3">
            <Button
              variant="success"
              name="status"
              value={"Approved"}
              onClick={(e) => {
                handleChange(e);
                onHide();
              }}
            >
              Approved
            </Button>
            <Button
              variant="danger"
              name="status"
              value={"Rejected"}
              onClick={(e) => {
                handleChange(e);
                onHide();
              }}
            >
              Rejected
            </Button>

            <Modal show={show} onHide={onHide}>
              <Modal.Body>
                <p>Are you sure ?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onHide();
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setSubmitTrigger(true);
                    onHide();
                  }}
                >
                  Save changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default PendingRequestDetail;
