import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TabNavItem from "../../Tools/TabNavItem";
import { pricingFun } from "../../../Reducer/pricing/pricingSlice";
import Subscription from "./subscription";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditForm from "./editForm";
import AddForm from "./addForm";
import { getAllSubPlans } from "../../../Reducer/subscription/subscriptionSlice";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [show, setShow] = React.useState(false);
  const [addShow, setAddShow] = React.useState(false);
  const tradeUsers = useSelector((state) => state.pricing?.trade_users);

  const { success, error, submitting, all_plans } = useSelector(
    (state) => state.subscription
  );
  const [active, setActive] = React.useState("");
  const [data, setData] = React.useState([]);
  const [idd, setIdd] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAllSubPlans());
  }, [dispatch, show]);

  const onClick = (id) => {
    setIdd(id);
    setShow(!show);
  };

  return (
    <div className="dashnboard pricing">
      <h3>
        {"Subscription controls"}
        <Link className="freeze_btn" onClick={() => setAddShow(!addShow)}>
          {"Add plans"}
        </Link>
        &nbsp;
      </h3>
      <Subscription
        data={all_plans.length > 0 ? all_plans : tradeUsers}
        onClick={onClick}
      />
      <BoostrapModel
        style={"Edit_section"}
        size={"lg"}
        show={show}
        component={<EditForm id={idd} onHide={() => setShow(!show)} />}
        onHide={() => setShow(!show)}
        heading={"Edit plan"}
        onClick={(id) => onClick(id)}
      />
      <BoostrapModel
        style={"Edit_section"}
        size={"lg"}
        show={addShow}
        component={<AddForm onHide={() => setAddShow(!addShow)} />}
        onHide={() => setAddShow(!addShow)}
        heading={"Add plan"}
      />
    </div>
  );
};
export default Pricing;
