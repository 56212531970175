import React from "react";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import Button from "../../Tools/Button";
import { useDispatch, useSelector } from "react-redux";
import { addSpecializationFun } from "../../../Reducer/Specialization/specializationSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

const AddSpecialization = ({ onHide }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log(errors);
  const dispatch = useDispatch();
  const { submitting, error, success } = useSelector(
    (state) => state.specialization
  );

  const onSubmit = async (data) => {
    dispatch(addSpecializationFun(data));
    if (success) {
      onHide();
    }
  };

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit)}>
        <FormGroupLabelInput
          label={"Specialization name"}
          parentStyle={"form-group"}
          childStyle={"form-control"}
          event={register}
          name={"name"}
          placeholder={"Specialization name"}
        />

        <div className="modal-footer">
          <Button
            style={"btn btn-secondary"}
            title={"Close"}
            type={"button"}
            onClick={() => onHide()}
          ></Button>
          <Button
            style={"btn btn-primary"}
            type={"submit"}
            title={"Add"}
          ></Button>
        </div>
      </form>
    </>
  );
};

export default AddSpecialization;
