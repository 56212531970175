import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBlockWordApi } from '../../services/api';

export const BlockWordFun = createAsyncThunk('/Block_words', async (formData, { rejectWithValue }) => {
    try {
      const response = await getBlockWordApi(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

const blockWordsSlice = createSlice({
  name: 'blockWords',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    language:'',
    blockWords:[],
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(BlockWordFun.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(BlockWordFun.fulfilled, (state,action) => {
        state.submitting = false;
        state.success=true;
        state.blockWords=action.payload;
      })
      .addCase(BlockWordFun.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.error;
        state.success = false;
      })
  },
});
export default blockWordsSlice.reducer;

