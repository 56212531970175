import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionListBoxes from "./sectionListBoxes";
import { editPageFun } from "../../../Reducer/pages/editpageSlice";

const EditPage = () => {
    const dispatch = useDispatch();
    const urlString = window.location.href;
    const urlSegments = urlString.split('/');
    const lastItem = urlSegments[urlSegments.length - 1];

    React.useEffect(() => {
        const formData = new FormData();
        formData.append('id', lastItem);
        dispatch(editPageFun(formData));
    }, [dispatch])
    const rows = useSelector(state => state.editPage?.editPage);
    return (<div class="overview edit_page">
        <h3>{"Edit home page"}</h3>
        <SectionListBoxes rows={rows && rows} parentStyle={"col-sm-6 col-lg-4 col-xl-3 mb-3"} childStyle={"set"} />
    </div>)
}

export default EditPage;