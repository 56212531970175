const FormGroupLabelInput = ({
  label,
  name,
  event,
  parentStyle,
  childStyle,
  placeholder,
}) => {
  return (
    <div class={parentStyle}>
      <label>{label}</label>
      <input
        class={childStyle}
        {...event(name, { required: "Required" })}
        placeholder={placeholder}
      />
    </div>
  );
};
export default FormGroupLabelInput;
