import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { editBlog, getSingleBlog } from "../../../Reducer/blogs/blogSlice";
import { BaseUrl, editorFormats, editorModules } from "../../../data/data";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCategories,
  getSubCategories,
} from "../../../Reducer/category/categorySlice";
import Tags from "../../Tools/Tags";
import moment from "moment";
import { findSlug } from "../../../services/api";
import { Base64 } from "js-base64";

const EditBlogs = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [faq, setFaq] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [removeBlogImage, setRemoveBlogImage] = useState(null);
  const [removeAuthorImage, setRemoveAuthorImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [desc, setDesc] = useState();
  const [dltTag, setDltTag] = useState([]);
  const [slugAvail, setSlugAvail] = useState([]);
  const [addedTags, setAddedTags] = useState([]);

  const { slug } = useParams();

  const dispatch = useDispatch();
  const { singleBlog, success, submitting, error } = useSelector(
    (state) => state.blogs
  );
  const { all_categories, sub_categories } = useSelector(
    (state) => state.category
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleBlog(slug));
    dispatch(getCategories(0));
  }, []);

  useEffect(() => {
    if (success) {
      setFaq([]);
      dispatch(getSubCategories(singleBlog.cat_id));
      setDesc(singleBlog?.description);
      setValue("title", singleBlog.title);
      setValue("image", singleBlog.image);
      setValue("slug", singleBlog.slug);
      setValue("meta_title", singleBlog.meta_title);
      setValue("meta_description", singleBlog.meta_description);
      setValue("short_description", singleBlog.short_description);
      setValue("cat_id", singleBlog.cat_id);
      setValue("subcat_id", singleBlog.subcat_id);
      setValue("author_name", singleBlog.author_name);
      setValue("author_image", singleBlog.author_image);
      setValue("author_bio", singleBlog.author_bio);
      setValue("publishDate", singleBlog?.created_at?.split("T")[0]);
      setValue("author_image", singleBlog.author_image);
      if (singleBlog.faqs && singleBlog.faqs.length > 0) {
        try {
          const parsed = JSON.parse(singleBlog?.faqs);
          setFaq([parsed]);
          const decodedFaq = parsed.map((element) => ({
            que: element.que,
            ans: Base64.decode(element.ans),
          }));
          setFaq(decodedFaq);
        } catch (error) {
          console.log(error);
        }
      }
      if (singleBlog.meta_tags) {
        setMetaTags(JSON.parse(singleBlog?.meta_tags));
      }

      getTags();
    }
  }, [singleBlog]);

  const getTags = () => {
    const newTags = singleBlog?.tags?.map((item) => ({
      id: item.tag,
      text: item.tag,
    }));

    setTags(newTags);
  };

  const handleCategory = (e) => {
    const { value } = e.target;
    dispatch(getSubCategories(value));
  };

  const onSubmit = async (data) => {
    try {
      const newArr = faq.map((elem) => ({
        que: elem.que,
        ans: Base64.encode(elem.ans),
      }));

      console.log(newArr);

      const addHeadingIds = desc
        .match(/<h[1-3]>(.*?)<\/h[1-3]>/g)
        ?.map((x) => x.match(/<h[1-3]>(.*?)<\/h[1-3]>/)[1]);

      const result = desc.replace(/<h[1-3]>/g, function (match) {
        const headingId = addHeadingIds.shift();
        const headingLevel = match.charAt(2);
        return `<h${headingLevel} id="${headingId}">`;
      });

      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", result);
      formData.append("image", data.image[0]);
      formData.append("time", moment(data.publishDate).unix());
      formData.append("cat_id", data.cat_id);
      formData.append("subcat_id", data.subcat_id);
      formData.append("author_name", data.author_name);
      formData.append("short_description", data.short_description);
      formData.append("author_bio", "");
      formData.append("author_image", data.author_image[0]);
      formData.append("slug", data.slug);
      for (let index = 0; index < addedTags.length; index++) {
        formData.append("tags[]", addedTags[index].text);
      }
      formData.append("meta_title", data.meta_title);
      formData.append("meta_description", data.meta_description);
      formData.append("faqs", JSON.stringify(newArr));
      formData.append("meta_tags", JSON.stringify(metaTags));

      for (let index = 0; index < dltTag.length; index++) {
        formData.append("deleteTag[]", dltTag[index]);
      }
      if (removeBlogImage) {
        formData.append("oldImageBanner", removeBlogImage);
      }
      if (removeAuthorImage) {
        formData.append("oldImageAuthor", removeAuthorImage);
      }

      const body = { id: singleBlog.id, formData: formData };
      dispatch(editBlog(body));
      navigate("/blogs");
    } catch (error) {
      console.log(error);
    }
  };

  const handleFaqQue = (e, i) => {
    let newFaq = [...faq];
    newFaq[i][e.target.name] = e.target.value;
    setFaq(newFaq);
  };

  const handleFaqAns = (content, i) => {
    let newFaq = [...faq];
    newFaq[i]["ans"] = content;
    setFaq(newFaq);
  };

  let handleRemove = (i) => {
    let newFaq = [...faq];
    newFaq.splice(i, 1);
    setFaq(newFaq);
  };

  const handleChange = (content, delta, source, editor) => {
    setDesc(content);
  };

  const handleImage = (e) => {
    const { name, files } = e.target;
    console.log(name, files);
    if (name === "image") {
      setRemoveBlogImage(singleBlog.image);
    } else {
      setRemoveAuthorImage(singleBlog.author_image);
    }
  };

  const handleSlug = async (e) => {
    try {
      const body = { id: singleBlog, slug: e.target.value };
      const response = await findSlug(body);
      setSlugAvail(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddFaq = () => {
    setFaq([...faq, { que: "", ans: "" }]);
  };

  return (
    !submitting && (
      <div className="edit-blogs">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <img
              src={`${BaseUrl}/${singleBlog?.image}`}
              height={"100px"}
              alt="blog_image"
            />
          </div>
          <div className="form-group">
            <label>Blog Image</label>
            <input
              type="file"
              className="form-control"
              name="image"
              {...register("image")}
              onChange={handleImage}
            />
          </div>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              name="title"
              {...register("title", { required: "Title is Required" })}
            />
          </div>
          <div className="form-group">
            <label>Slug</label>
            <input
              type="text"
              className="form-control"
              name="slug"
              {...register("slug", { required: "Slug is Required" })}
              onChange={handleSlug}
            />
          </div>
          <p>{slugAvail.message}</p>

          <div className="form-group">
            <label>Description</label>
            <ReactQuill
              theme="snow"
              modules={editorModules}
              formats={editorFormats}
              value={desc}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Short Description</label>
            <input
              type="text"
              className="form-control"
              name="short_description"
              {...register("short_description", {
                required: "short description is Required",
              })}
              onChange={handleSlug}
            />
          </div>
          <div className="form-group">
            <label>Meta Title</label>
            <input
              type="text"
              className="form-control"
              name="meta_title"
              {...register("meta_title", {
                required: "Meta  Title is Required",
              })}
            />
          </div>
          <div className="form-group">
            <label>Meta Description</label>
            <input
              type="text"
              className="form-control"
              name="meta_description"
              {...register("meta_description", {
                required: "Meta description is Required",
              })}
            />
          </div>
          <div className="form-group my-3">
            <label>Faq</label>
            <Button onClick={handleAddFaq}> Add Faq</Button>
          </div>
          {faq.length > 0 &&
            faq.map((el, i) => (
              <div className="form-group" key={i}>
                <label>Question</label>
                <input
                  type="text"
                  className="form-control"
                  name="que"
                  value={el.que}
                  onChange={(e) => handleFaqQue(e, i)}
                />
                <label>Answer</label>
                <ReactQuill
                  theme="snow"
                  modules={editorModules}
                  formats={editorFormats}
                  name="ans"
                  value={el.ans}
                  onChange={(content) => handleFaqAns(content, i)}
                />
                <Button onClick={() => handleRemove(i)}>Remove</Button>
              </div>
            ))}

          <div className="form-group my-3">
            <label>Meta Tags</label>
            <Tags tags={metaTags} setTags={setMetaTags} />
          </div>

          <div className="col-sm-12 mb-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="cat_id"
                className="form-control"
                {...register("cat_id", { required: "Category is Required" })}
                onChange={handleCategory}
              >
                {all_categories &&
                  all_categories.length > 0 &&
                  all_categories.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-sm-12 mb-3">
            <div className="form-group">
              <label>Sub Category</label>
              <select
                name="subcat_id"
                className="form-control"
                {...register("subcat_id")}
              >
                {sub_categories &&
                  sub_categories.length > 0 &&
                  sub_categories.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div>
            <img
              src={`${BaseUrl}/${singleBlog.author_image}`}
              alt="author_image"
            />
          </div>
          <div className="form-group">
            <label>Author Image</label>
            <input
              type="file"
              className="form-control"
              name="author_image"
              {...register("author_image")}
              onChange={handleImage}
            />
          </div>
          <div className="form-group">
            <label>Author Name</label>
            <input
              type="text"
              className="form-control"
              name="author_name"
              {...register("author_name", {
                required: "Author name is Required",
              })}
            />
          </div>

          <div className="form-group">
            <label>Publish Date</label>
            <input
              type="date"
              className="form-control"
              name="publishDate"
              {...register("publishDate", {
                required: "Publish Date is Required",
              })}
            />
          </div>
          <div className="form-group my-3">
            <label>Tags</label>
            <Tags
              tags={tags}
              setTags={setTags}
              dltTag={dltTag}
              setDltTag={setDltTag}
              addedTags={addedTags}
              setAddedTags={setAddedTags}
              DataTags={singleBlog?.tags}
            />
          </div>
          <Button type="submit">Save Changes</Button>
          <Button variant="">Cancel</Button>
        </form>
      </div>
    )
  );
};

export default EditBlogs;
