import React, { useEffect, useState } from "react";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addCategories } from "../../../Reducer/category/categorySlice";
import axios from "axios";
import { findCategoryAvail } from "../../../services/api";
import FormGroupOption from "../../Tools/FormGroupOption";
import { helpCategoryOptions } from "../../../data/data";
import {
  addHelpCategory,
  editHelpCategory,
} from "../../../Reducer/Help/helpSlice";

const EditHelpCategory = ({ onHide, editData }) => {
  const [categoryStatus, setCategoryStatus] = useState([]);
  const [OldImage, setOldImage] = useState(null);

  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    setValue("name", editData.name);
    setValue("type", editData.type);
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    const id = editData.id;
    try {
      formData.append("name", data.name);
      formData.append("type", data.type);
      if (OldImage) {
        formData.append("OldImage", OldImage);
        formData.append("image", data.image[0]);
      }
      dispatch(editHelpCategory({ id, data: formData }));
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class={"form-group"}>
          <label>{"Category Image"}</label>
          <input
            class={"form-control"}
            type="file"
            name="image"
            {...register("image")}
            onChange={() => setOldImage(editData.image)}
          />
        </div>
        <div class={"form-group"}>
          <label>{"Category Name"}</label>
          <input
            class={"form-control"}
            name="name"
            {...register("name", { required: "Name is required" })}
          />
        </div>

        <p>{categoryStatus.message}</p>

        <div class={"col-sm-6 mb-3"}>
          <div class={"form-group"}>
            <label>Type</label>
            <select
              class={"form-control"}
              name="type"
              {...register("type", { required: "Type is required" })}
            >
              {helpCategoryOptions.length > 0 &&
                helpCategoryOptions.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="modal-footer"></div>
        <Button type="submit">Save Changes</Button>
      </form>
    </div>
  );
};

export default EditHelpCategory;
