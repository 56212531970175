import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import FormGroupOption from "../../Tools/FormGroupOption";
import { useForm } from "react-hook-form";
import FormGroupLabelInput from "../../Tools/FormGroupLabelInput";
import Button from "../../Tools/Button";
import { useDispatch } from "react-redux";
import { editSelectionDoc } from "../../../Reducer/selection/selectionSlice";
const EditSelection = ({ show, onHide, selectedData }) => {
  const status_options = ["enable", "disable"];
  const type_options = ["claim"];

  const { register, setValue, handleSubmit } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("name", selectedData?.name);
    setValue("status", selectedData?.status);
    setValue("type", selectedData?.type);
  }, []);

  const onSubmit = async (data) => {
    dispatch(editSelectionDoc({ id: selectedData.id, data: data }));
    onHide();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroupLabelInput
        label={"Name"}
        name={"name"}
        event={register}
        placeholder={"Name"}
        parentStyle={"form-group"}
        childStyle={"form-control"}
      />
      <FormGroupOption
        label={"Status"}
        event={register}
        options={status_options}
        name={"status"}
        parentStyle={"col-sm-12 mb-3"}
        childStyle={"form-group"}
        selectStyle={"form-control"}
      />
      <FormGroupOption
        label={"Type"}
        event={register}
        options={type_options}
        name={"type"}
        parentStyle={"col-sm-12 mb-3"}
        childStyle={"form-group"}
        selectStyle={"form-control"}
      />
      <div className="modal-footer">
        <Button
          style={"btn btn-secondary"}
          title={"Close"}
          type={"button"}
          onClick={() => onHide()}
        ></Button>
        <Button
          style={"btn btn-primary"}
          type={"submit"}
          title={"Edit"}
        ></Button>
      </div>
    </form>
  );
};

export default EditSelection;
